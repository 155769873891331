import React, { useState, useEffect } from 'react'
import { useGlobalContext } from '../../../GlobalContext'

import ModuleIdentification from './ModuleIdentification'
import ModuleContacts from './invitations/ModuleContacts'
import SupportContact from '../../../partials/SupportContact'

export default function Inscription(){
  const [ isPresence, setPresence ] = useState(null)
  const context = useGlobalContext()[0];
  const companyReducer = context.companyReducer;

  useEffect(()=>{
    if(typeof companyReducer.company?.presence === "boolean"){
      setPresence(companyReducer.company.presence)
    }

    // eslint-disable-next-line
  },[companyReducer.company?.presence])


  function handlePresence(val){
    setPresence(val)
  }

  return (
    <>
      <div className={"store-form"}>
        <div className="content large">
          <div className="title-page">
            <h2>Mon inscription</h2>
          </div>
          <ModuleIdentification isPresence={companyReducer.company?.presence} company={companyReducer.company} handlePresence={handlePresence}/>
          {companyReducer.company && isPresence ?
            <ModuleContacts users={companyReducer.companyUsers} activities={companyReducer.company.domains}/> : null
          }
        </div>
        <SupportContact pageForm={true}/>
      </div>
    </>
  )
}
