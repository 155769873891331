import {
  MODAL_REGLEMENT_QUIZZ
} from "../actions/types"

const initialState = { reglementQuizz : false};
let updateAnimation;

export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case MODAL_REGLEMENT_QUIZZ :
      return {...state, reglementQuizz : action.payload};
    default:
      return {...state};
  }
}
