import React, { useState, useEffect } from 'react'
import _ from "lodash";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useGlobalContext } from '../../../GlobalContext'
import { deleteCompanyUser } from '../../../../actions/company'
import ReactTooltip from 'react-tooltip';


import IconCocktailGrey from '../../../../assets/images/icons/icon-cocktail-grey.svg'
import IconCocktailWhite from '../../../../assets/images/icons/icon-cocktail-white.svg'
import IconPencil from '../../../../assets/images/icons/icon-pencil.svg'
import IconPencilWhite from '../../../../assets/images/icons/icon-pencil-white.svg'
import IconPencilHover from '../../../../assets/images/icons/icon-pencil-hover.svg'
import DustBinIcon from '../../../../assets/images/icons/dustbin_icon.svg'
import DustBinIconWhite from '../../../../assets/images/icons/dustbin_icon-white.svg'
import DustBinIconHover from '../../../../assets/images/icons/dustbin_icon_hover.svg'

import FormContributors from './FormContributors'

import styles from './contributors.module.scss';

export default function LineUser({user, isLoading, edit, selectUser, selectedUser, cancel}){
  const [context, dispatch] = useGlobalContext();
  const companyReducer = context.companyReducer


  //ACTIONS
  const _deleteCompanyUser = (id) => deleteCompanyUser(dispatch,id)

  function renderPresenceDays(days = [], afterwork){
    if(!companyReducer.companyList) return;
    let arr = []

    let found = companyReducer.companyList.days.filter(opt=>{
      return days.includes(opt.value)
    })

    if(found){
      arr = found.map(d => d.label)
    }

    const getDateStr = (date) => {
      const day = format(new Date(date), 'd MMMM', { locale: fr });
      return day;
    }

    return (
      <ul >
        <li className="only-on-tablet"><label>Présence sur le salon :</label></li>
        {companyReducer.companyList.days.map((d, i) => {
          return (
            <li key={'day-'+i} className={_.includes(arr,d.label) ? styles.day : `${styles.day} ${styles.disabled}`}>
              <p>
                <span>{getDateStr(d.value)}</span>
              </p>
            </li>
          )
        })}
      </ul>
    )
  }

  function deleteUser(){
    const isConfirm = window.confirm("Attention, cette action supprimera l’utilisateur. Si il est déclaré comme “autre contact” de votre société, il ne recevra plus de mail. Voulez-vous continuer ?");
    if(isConfirm === true){
      _deleteCompanyUser(user._id)
    }
  }

  function toggleFormContact(){
    if(edit){
      cancel()
    }else{
      selectUser(user._id)
    }
  }

  function getClassName(){
    if(selectedUser && selectedUser !== user._id){
      return `${styles['line-user']} ${styles['fade-out']}`
    }else{
      return isLoading && isLoading.action === "delete" ? `${styles['line-user']} ${styles.loading}` : styles['line-user']
    }
  }

  return (
    <>
    {!edit &&
      <div className={getClassName()}>
        <div className={`${styles.container} container hide-on-mobile`}>
          <div><p className={styles.name}>{user.profile.firstName} {user.profile.lastName}</p></div>
          <div><p>{user.profile.function}</p></div>
          <div><p>{user.email.split("//")[0]}</p></div>
        </div>
        <div className={styles['presence-days']}>{renderPresenceDays(user.presence)}</div>
        <div className={styles.edit}>
          <div className={`${styles.user} only-on-mobile`}>
            <div><p className={styles.name}>{user.profile.firstName} {user.profile.lastName}</p></div>
            <div><p>{user.profile.function}</p></div>
            <div><p>{user.email.split("//")[0]}</p></div>
          </div>
          <div className={styles['toggle-edit']} onClick={toggleFormContact}>
            <label className="only-on-desktop">Modification</label>
            <img src={IconPencilHover} className="hide-on-mobile" alt={"modifier "+user.profile.firstName+" "+user.profile.lastName} />
            <img src={IconPencilHover} className="hide-on-mobile" alt={"modifier "+user.profile.firstName+" "+user.profile.lastName} />
            <img className="only-on-mobile" src={IconPencilWhite} alt={"modifier "+user.profile.firstName+" "+user.profile.lastName} />
          </div>
          { user.role !== "Owner" &&
            <div className={styles.delete} onClick={deleteUser}>
              <img className="hide-on-mobile" src={DustBinIcon} data-tip="Supprimer" alt={"supprimer "+user.profile.firstName+" "+user.profile.lastName} />
              <img className="hide-on-mobile" src={DustBinIconHover} data-tip="Supprimer" alt={"supprimer "+user.profile.firstName+" "+user.profile.lastName} />
              <img className="only-on-mobile" src={DustBinIconWhite} alt={"supprimer "+user.profile.firstName+" "+user.profile.lastName} />
            </div>
          }
        </div>
        { isLoading && isLoading.action === "update" ?
          <div className={styles.loader}></div> :
          null
        }

      </div>
    }
    {edit &&
      <FormContributors key={"update_"+user._id} isLoading={isLoading} user={user} cancel={cancel}/>
    }
      <ReactTooltip globalEventOff="click" effect="solid" className="custom-tooltip hide-on-tablet" backgroundColor="#005FBF"/>
    </>
  )
}
