import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';

import Smiley1 from '../../../assets/images/smiley-1.png'
import Smiley2 from '../../../assets/images/smiley-2.png'
import Smiley3 from '../../../assets/images/smiley-3.png'
import Smiley4 from '../../../assets/images/smiley-4.png'

export default function Question({index, question, handleAnswer, height}){

  return(
    <div className="content question" style={{height : height+"px"}}>
      <p>{question}</p>
      <ul>
        <li>
          <Animated trigger={() => handleAnswer(4)}><img src={Smiley1} alt="génial"/></Animated>
        </li>
        <li>
          <Animated trigger={() => handleAnswer(3)}><img src={Smiley2} alt="bien" /></Animated>
        </li>
        <li>
          <Animated trigger={() => handleAnswer(2)}><img src={Smiley3} alt="moyen"/></Animated>
        </li>
        <li>
          <Animated trigger={() => handleAnswer(1)}><img src={Smiley4} alt="déçu" /></Animated>
        </li>
      </ul>
    </div>
  )
}

const Animated = ({children, trigger}) => {

  const [ animationOn, setAnimationOn ] = useState(true);

  const { x } = useSpring({
    x: animationOn ? 0 : 1,
    config : {
      ...config.wobbly
    }
  })

  function runAnimation(){
    setAnimationOn(!animationOn)
    setTimeout(()=>{
      trigger()
    },800)
  }

  return(
    <animated.div  style={{
      transform: x.interpolate({
        range: [0, .5, 1],
        output: [1, .8, 1]
      }).interpolate(x => `scale(${x})`)
    }}>
      {React.cloneElement(children,{onClick : runAnimation})}
    </animated.div>
  )
}
