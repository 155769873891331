import React, { useEffect, useContext } from 'react';
import _ from "lodash";
import { NavLink, useHistory } from 'react-router-dom';
import { useGlobalContext } from '../../../../GlobalContext';
import { setFormShowroom } from '../../../../../actions/showroom';
import StepContext from '../StepContext';
import Dimensions from './Dimension';
import ListOptions from './ListOptions';
import SupportContact from '../../../../partials/SupportContact';


export default function Step1({ isLoading }) {
  const [ context, dispatch ] = useGlobalContext();
  const { authReducer, showRoomReducer, companyReducer } = context;
  const stepContext = useContext(StepContext);
  const history = useHistory();

  const { exhibition } = authReducer;

  //ACTIONS
  const _setFormShowroom = (data) => setFormShowroom(dispatch,data)

  /// INIT FORMDATA WITH COMPANYREDUCER AND AUTHREDUCER
  useEffect(() => {
    if(!companyReducer.company || !authReducer.user) return;
    let updatedData = _.cloneDeep(showRoomReducer.formData);
    updatedData.company = companyReducer.company._id;
    updatedData.user = authReducer.user._id;
    _setFormShowroom(updatedData);
  }, [
    companyReducer.company,
    authReducer.user,
  ]);

  useEffect(()=>{
    if(history.location.pathname !== "/stand/step-1"){
      let updatedData = _.cloneDeep(showRoomReducer.formData);
      _setFormShowroom(updatedData);
    }
  },[history.location.pathname])

  function renderButtonValidated(){
    let disabled = "disabled";
    if(stepContext.step1) disabled = '';
    return (
      <>
        { disabled === 'disabled' ?
          <button className={"disabled"}>Finaliser</button> :
          <NavLink to="/stand/recapitulatif">
            <button>Finaliser</button>
          </NavLink>
        }
      </>
    );
  }

  return(
    <div className="step step1">
      {exhibition.displayShowroomSize && <Dimensions/>}
      <ListOptions isLoading={isLoading}/>
      <div className="container-support">
        <SupportContact pageForm={true}/>
        {renderButtonValidated()}
      </div>
    </div>
  )

}
