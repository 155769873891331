import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';

export default function Question({index, question, handleAnswer, height}){

  return(
    <div className="content question" style={{height : height+"px"}}>
      <p>Quel serait, pour vous, le mois le plus adapté pour venir au salon {process.env.REACT_APP_NAME} :</p>
      <ul>
        <li>
          <Animated trigger={() => handleAnswer("Juin")}><div className="answer-text">Juin</div></Animated>
        </li>
        <li>
          <Animated trigger={() => handleAnswer("Septembre")}><div className="answer-text">Septembre</div></Animated>
        </li>
      </ul>
    </div>
  )
}

const Animated = ({children, trigger}) => {

  const [ animationOn, setAnimationOn ] = useState(true);

  const { x } = useSpring({
    x: animationOn ? 0 : 1,
    config : {
      ...config.wobbly
    }
  })

  function runAnimation(){
    setAnimationOn(!animationOn)
    setTimeout(()=>{
      trigger()
    },800)
  }

  return(
    <animated.div  style={{
      transform: x.interpolate({
        range: [0, .5, 1],
        output: [1, .8, 1]
      }).interpolate(x => `scale(${x})`)
    }}>
      {React.cloneElement(children,{onClick : runAnimation})}
    </animated.div>
  )
}
