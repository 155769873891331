import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useInitCompany from '../../customHooks/useInitCompany';

import Loader from '../partials/Loader';

export default function Redirect() {
  const initCompany = useInitCompany();
  const history = useHistory();

  useEffect(() => {
    if (!initCompany.type || !initCompany.role) return;
    if (initCompany.type === "fournisseur" || initCompany.type === "magasin") {
      history.push("/salon");
    }
  }, [initCompany.type,initCompany.role]);

  return (
    <Loader/>
  );

};
