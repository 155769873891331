import React, { useState, useEffect, useRef } from 'react';
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom';
import useDimension from '../../../customHooks/useDimension';
import IconCrossHair from '../../../assets/images/icons/icon-crosshair.svg';

import InlineSVG from 'svg-inline-react';
import SvgPlan from './SvgPlan2022'

export default function Plan(){
  const viewer = useRef()
  const dimension = useDimension();

  const width = 1000
  const height = 712

  useEffect(()=>{
    let zoomFactor;
    if(dimension.width < 1000){
      zoomFactor = (dimension.width/width)/1.3
    }else {
      zoomFactor = (dimension.width/width)/2
    }

    viewer.current.setPointOnViewerCenter(width/2,(height+200)/2,zoomFactor)
    // viewer.current.fitToViewer("center","center")
  },[])

  function fitToViewer() {
    let zoomFactor;
    if(dimension.width < 1000){
      zoomFactor = (dimension.width/width)/1.3
    }else {
      zoomFactor = (dimension.width/width)/2
    }
        viewer.current.setPointOnViewerCenter(width/2,(height+200)/2,zoomFactor)
  }

  return (
    <div className="plan">
      <button onClick={fitToViewer}>
        <img src={IconCrossHair} alt="icon crosshair"/>
        Recentrer le plan
      </button>
      <UncontrolledReactSVGPanZoom
        width={dimension.width} height={dimension.height}
        ref={viewer}
        tool={"auto"}
        scaleFactorMin={.5}
        scaleFactorMax={2.2}

        background={"#F9F9FB"}
        preventPanOutside={true}
        detectAutoPan={false}
        customMiniature={() => <></>}
        customToolbar={() => <></>}
      >
      <svg width={width} height={height}>
        <g dangerouslySetInnerHTML={{__html: SvgPlan}} />
      </svg>
      </UncontrolledReactSVGPanZoom>
    </div>
  )
}
