import React from 'react';

import SupportContact from '../../partials/SupportContact'
import Block from './Block'

import IconMartini from '../../../assets/images/icons/icon-martini.svg'
import ImgCocktail from '../../../assets/images/picto_cocktail.png'

export default function Cocktail (props){
  return(
    <>
      <div className="cocktail content large">
        <Block
              isOpen={true}
              title="Mercredi 14 septembre 2022"
            >
              <div>
                <img src={ImgCocktail} alt="café" />
              </div>
                <div>
                  <img src={IconMartini} alt="conférence" />
                  <p>18H00-19H30<br/>Cocktail</p>
                </div>
              <div>
                <h3>
                  Inscrivez-vous au Cocktail after work…
                </h3>
                <p>Venez partager un moment de convivialité et d’échange lors du cocktail after work qui se tiendra à la sortie du Salon le mercredi 14/09. Equipes BT, Galec, Expertise, collaborateurs magasins, fournisseurs partenaires&nbsp;: le salon joue les prolongations en mode partage !</p>
                <p className="info">Pensez à bien vous inscrire.</p>
              </div>
            </Block>
      </div>
      <div className="support content large">
        <SupportContact breakingPoint={900}/>
      </div>
    </>
  )
}
