import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router'
import InputField from '../../partials/form_fields/InputField'

const Form  = ({submit, handleForm, data,height}) => {
  const history = useHistory()

  return (
    <div className="content form" style={{minHeight : height+"px"}}>
      <p>
        Vous souhaitez une réponse, laissez nous vos coordonnées :
      </p>
      <div className="centered">
        <InputField
          value={data.name}
          title={"Nom"}
          placeholder={""}
          type={"string"}
          handleChange={(val,error) => handleForm("name",val)}
        />
        <InputField
          value={data.email}
          title={"Adresse mail"}
          placeholder={""}
          format={"email"}
          type={"email"}
          handleChange={(val,error) => handleForm("email",val)}
        />
        <InputField
          title={"Téléphone"}
          value={data.phone}
          placeholder={""}
          type={"string"}
          maskNumber={"## ## ## ## ##"}
          format={"phone"}
          handleChange={(val,err) => handleForm("phone",val)}
        />
        <button onClick={submit}>
          Valider
        </button>
        <button className="secondary" onClick={() => history.push("/salon")}>
          Non merci
        </button>
      </div>
    </div>
  )
}

export default Form
