import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import ImgSubmit from '../../../../assets/images/img-submit.svg'
import { useGlobalContext } from '../../../GlobalContext'
import { RESET_SUBMITTED } from '../../../../actions/types'

export default function SubmitSucceed(){
  const dispatch = useGlobalContext()[1];

  useEffect(()=>{
    dispatch({type : RESET_SUBMITTED})
  },[])

  return(
    <div className="step submit-succeed">
      <div>
        <h1>Votre inscription</h1>
        <h2>a bien été prise en compte !</h2>
        {/* <span className="hide-on-tablet">
          <NavLink exact to={"/animations"} >
            <button>Mes animations</button>
          </NavLink>
        </span> */}
        {/* <span className="only-on-tablet">
          <NavLink exact to={"/animations#select"} >
            <button>Mes animations</button>
          </NavLink>
        </span> */}
      </div>
      <div>
        <img src={ImgSubmit} alt="submit succeed"/>
      </div>

    </div>
  )

}
