import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';

export default function Question({index, question, handleAnswer, height}){

  return(
    <div className="content question" style={{height : height+"px"}}>
      <p>Où avez-vous déjeuné ?</p>
      <ul>
        <li>
          <Animated trigger={() => handleAnswer("Food Truck Le Joconde")}><div className="answer-text">Food Truck Le Joconde</div></Animated>
        </li>
        <li>
          <Animated trigger={() => handleAnswer("Food Truck Cam'Tarte")}><div className="answer-text">Food Truck Cam'Tarte</div></Animated>
        </li>
        <li>
          <Animated trigger={() => handleAnswer("Restauration intérieur")}><div className="answer-text">L'espace restauration intérieur</div></Animated>
        </li>
        <li>
          <Animated trigger={() => handleAnswer("Terrasses de l'Erdre")}><div className="answer-text">Terrasses de l'Erdre</div></Animated>
        </li>
        <li>
          <Animated trigger={() => handleAnswer("Autre")}><div className="answer-text">Autre</div></Animated>
        </li>
      </ul>
    </div>
  )
}

const Animated = ({children, trigger}) => {

  const [ animationOn, setAnimationOn ] = useState(true);

  const { x } = useSpring({
    x: animationOn ? 0 : 1,
    config : {
      ...config.wobbly
    }
  })

  function runAnimation(){
    setAnimationOn(!animationOn)
    setTimeout(()=>{
      trigger()
    },800)
  }

  return(
    <animated.div  style={{
      transform: x.interpolate({
        range: [0, .5, 1],
        output: [1, .8, 1]
      }).interpolate(x => `scale(${x})`)
    }}>
      {React.cloneElement(children,{onClick : runAnimation})}
    </animated.div>
  )
}
