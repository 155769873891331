import React, { useMemo, useState, useEffect, useContext } from "react";
import _ from "lodash"
import {useTransition, animated} from 'react-spring';
import { Switch, Route, useHistory } from "react-router-dom";
import { useGlobalContext } from '../../../GlobalContext'

import { StepProvider } from './StepContext'
import ModalContext from '../../../ModalContext'

import HeaderStandForm from './HeaderStandForm'
import Step1 from './step1/Step1'
import Recapitulatif from './recapitulatif/Recapitulatif'
import SubmitSucceed from './SubmitSucceed'
import ModalSupportContact from '../../../partials/ModalSupportContact'

const StandForm = (props) => {
  const context = useGlobalContext()[0];
  const { authReducer, showRoomReducer } = context;
  const history = useHistory();
  const { exhibition } = authReducer;

  const modalContext = useContext(ModalContext);

  const transitions = useTransition(history.location, location => location.pathname, {
    from: { opacity: 0, transform : 'translate(40%,0)' },
    enter: { opacity: 1, transform : 'translate(0,0)' },
    leave: { opacity: 0, transform : 'translate(-40%,0)' },
  })

  // STATE / CONTEXT
  const [ step1, setStep1 ] = useState(false)

  //NOTE : set step 1 if there are all needed data
  useEffect(()=>{
    if(_.isEmpty(showRoomReducer.formData)) return;

    if (showRoomReducer.formData?.dimension 
      || !exhibition.displayShowroomSize
    ) {
       setStep1(true);
    }
  },[
    exhibition.displayShowroomSize,
    showRoomReducer.formData,
  ])

  //NOTE : set step 1 if there are all needed data
  useEffect(()=>{
    if(_.isEmpty(showRoomReducer.formData) || !showRoomReducer.formData.option) return;

    let done = true;
    const countChair = typeof showRoomReducer.formData.option.chair === 'number' ? showRoomReducer.formData.option.chair : showRoomReducer.formData.option.countChair;
    const countTable = typeof showRoomReducer.formData.option.table === 'number' ? showRoomReducer.formData.option.table : showRoomReducer.formData.option.countTable;

    const fridgeValue = true;

    if (exhibition.fridgeAvailable && exhibition.fridgeProvided) {
      const countFridge = typeof showRoomReducer.formData.option.fridge === 'number' ? showRoomReducer.formData.option.fridge : showRoomReducer.formData.option.countFridge;
      if (!countFridge && showRoomReducer.formData.option.fridge) {
        done = false;
      }
    } else if (exhibition.fridgeAvailable) {
      const fridgeLength = showRoomReducer.formData.option.fridgeLength;
      if (!fridgeLength && showRoomReducer.formData.option.fridge) {
        done = false;
      }
    }

    if (
      (exhibition.chairAvailable && !countChair && showRoomReducer.formData.option.chair)
      || (!countTable && showRoomReducer.formData.option.table)
      || (exhibition.powerAvailable && !showRoomReducer.formData.option.powerQuantity && showRoomReducer.formData.option.power)
    ) {
      done = false;
    }
    
    if (exhibition.displayShowroomSize &&
      (!showRoomReducer.formData.dimension 
      || showRoomReducer.formData.dimension === 0)
    ) done = false;

    setStep1(done);
  },[
    showRoomReducer.formData,
    exhibition.displayShowroomSize,
    exhibition.chairAvailable,
    exhibition.powerAvailable,
    exhibition.fridgeAvailable,
  ])

  return useMemo(()=>{

    let StepProviderValue = {
      step1,
      setStep1,
    }

    return (
      <>
      <div className="stand-form" style={{overflowX: "hidden"}}>
        <StepProvider value={StepProviderValue}>
          <div className="content">
            <HeaderStandForm {...props} user={authReducer.user}/>
          </div>
          <ModalSupportContact />
          {
            transitions.map(({ item, props, key }) => (
              <animated.div key={key} className="container-step" style={{...props}}>
                <div className="content">
                  <Switch location={item}>
                    <Route exact path="/stand/step-1/" render={
                      () => <Step1/>
                    }/>
                    <Route exact path="/stand/recapitulatif" render={
                      () => <Recapitulatif/>
                    }/>
                    <Route exact path="/stand/submit-succeed" component={SubmitSucceed}/>
                  </Switch>
                </div>
              </animated.div>
            ))
          }
        </StepProvider>
      </div>
      </>
    )
    // eslint-disable-next-line
  },[
    history.location.pathname,
    step1,
    modalContext.isModalOpen,
  ]);
}

export default StandForm;
