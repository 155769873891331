import {
  DEVICE_REGISTERED_NOTIFICATIONS,
  ERROR_NOTIFICATIONS
} from "../actions/types"

const initialState = { error : null, deviceId : null};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case DEVICE_REGISTERED_NOTIFICATIONS:
      return { ...state, deviceId : action.payload }
    case ERROR_NOTIFICATIONS :
      return {...state, error : action.payload };
    default:
      return {...state};
  }
}
