import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment'
import { getProviders } from '../../../actions/provider'
import { updateTrophyVote } from '../../../actions/users'
import { useGlobalContext } from '../../GlobalContext'
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.minimal.css';

import IconTrophy from '../../../assets/images/trophy.svg'
import Select from 'react-select';
import selectStyle from '../../partials/form_fields/selectStyle'
import Loader from '../../partials/Loader'
import useEventTakingPlace from '../../../customHooks/useEventTakingPlace'
import { dateEnd } from '../../../constant/index'

const Zoom = cssTransition({
  enter: 'zoomInToast',
  exit: 'zoomOutToast',
  duration: 600,
  appendPosition: false
});

export default function Trophy(){
  const [ context, dispatch] = useGlobalContext()
  const isEventTakingPlace = useEventTakingPlace()

  const providerReducer = context.providerReducer
  const authReducer = context.authReducer
  const [ multimediaVote , setMultimediaVote ] = useState()
  const [ maisonVote , setMaisonVote ] = useState()
  const [ optionsMultimedia, setOptionsMultimedia ] = useState();
  const [ optionsMaison, setOptionsMaison ] = useState();
  const isMount = useRef()
  const [ timeStamp, setTimeStamp ] = useState()
  const [ vote, setVote ] = useState({ "trophy": {} })
  const notifyVote = () => toast("Votre vote à bien été pris en compte", { autoClose : 2000, transition: Zoom, className : "success"});

  const [ disabledVote, setDisabledVote ] = useState(true)

  // ACTIONS
  const _getProviders = () => getProviders(dispatch)
  const _updateTrophyVote = (data) => updateTrophyVote(dispatch,data)

  useEffect(()=>{
    let dEnd = dateEnd
    let bool = moment().isAfter(dEnd)
    setDisabledVote(bool)

  },[]);

  useEffect(()=>{
    if(!providerReducer.providers){
      _getProviders()
    }else{
      // SET OPTIONS SELECT
      let optionsMultimedia = []
      let optionsMaison = []

      let providers = providerReducer.providers.filter(provider=>{
        return (
          provider.domains[0] !== "ACHATS INDIRECTS" &&
          provider.name !== "SIPLEC - MAISON" && provider.name !== "SIPLEC - MULTIMEDIA"
        )
      })

      providers.forEach((provider)=>{
        let option;
        if(provider.showroomNumber || provider.showroomNumber === 0){
          option = {label : provider.showroomNumber+" - "+provider.name, value : provider._id};
        }else{
          option = {label : provider.name, value : provider._id};
        }
        if(provider.domains.includes("MAISON") && provider.showroomNumber){
          optionsMaison.push(option)
        }
        if(provider.domains.includes("MULTIMEDIA") && provider.showroomNumber){
          optionsMultimedia.push(option)
        }
      })
      setOptionsMultimedia(optionsMultimedia)
      setOptionsMaison(optionsMaison)

      ///SET SELECTED OPTIONS
      if(authReducer.user.trophy &&
        (authReducer.user.trophy.multimedia || authReducer.user.trophy.maison)
      ){

        let vote = { trophy : {} }
        if(authReducer.user.trophy.maison){
          let findVoteMaison = optionsMaison.find(opt => opt.value === authReducer.user.trophy.maison)
          vote.trophy.maison = authReducer.user.trophy.maison
          setMaisonVote(findVoteMaison)
        }
        if(authReducer.user.trophy.multimedia){
          let findVoteMultimedia = optionsMultimedia.find(opt => opt.value === authReducer.user.trophy.multimedia)
          vote.trophy.multimedia = authReducer.user.trophy.multimedia
          setMultimediaVote(findVoteMultimedia)
        }

        setVote(vote)

      }

    }
  },[providerReducer.providers])

  function handleChange(val, target){
    if(target === "multimedia") setMultimediaVote(val)
    if(target === "maison") setMaisonVote(val)
    let obj = Object.assign({},vote);
    obj.trophy[target] = val.value
    setVote(obj)
    setTimeStamp(Date.now())
  }

  useEffect(()=>{
    if(!timeStamp) return;
    if(vote.trophy.multimedia || vote.trophy.maison){
      _updateTrophyVote(vote.trophy,authReducer.user._id).then(()=>{
        notifyVote()
      })
    }
  },[timeStamp])

  function openApp() {
    console.log('here');
    window.open('', '_system');
  }

  return (
    <div className="trophy">
      <div className="content">
        <img src={IconTrophy} alt="trophée" />
        <h1>
          Votez pour le plus<br/> beau stand
        </h1>
        <button onClick={() => openApp()}>Test</button>
        { isEventTakingPlace ?
          <>
          { (optionsMultimedia && optionsMaison) ?
            <div className="container-vote">
              <label>Stand multimedia</label>
              <Select
                onChange={(val) => handleChange(val,'multimedia')}
                options={optionsMultimedia}
                value={multimediaVote}
                isSearchable={false}
                styles={selectStyle}
              />
              <label>Stand maison</label>
              <Select
                onChange={(val) => handleChange(val,'maison')}
                options={optionsMaison}
                value={maisonVote}
                isSearchable={false}
                styles={selectStyle}
              />
              <p>Votre vote est modifiable pendant toute la durée du salon.</p>
            </div> : <Loader/>
          }
          </> :
          <>
            { disabledVote ?
              <div className="vote-done">
                { (authReducer.user && authReducer.user.trophy && (authReducer.user.trophy.maison || authReducer.user.trophy.multimedia)) &&
                  <h3>Merci d'avoir voté !</h3>
                }
                <p>
                  Le salon {process.env.REACT_APP_NAME} est terminé, il n'est donc plus possible de voter pour le plus beau stand.
                </p>
              </div> :
              <div className="vote-done">
                <p>Pendant le salon, votez pour le plus beau stand !</p>
              </div>
            }
          </>

        }

        <div className="centered">
          <NavLink to={"/cgu#trophy"}>Conditions générales d'utilisation</NavLink>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        hideProgressBar={true}
      />
    </div>
  )
}
