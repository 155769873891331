import React, { useState, useEffect, useRef, useMemo } from 'react';
import AnimatedTile from './AnimatedTile'
import { NavLink, useHistory } from 'react-router-dom';

import IconInfo from '../../../assets/images/tiles/icon-info.svg'
import IconOffres from '../../../assets/images/tiles/icon-offres.svg'
import IconBadge from '../../../assets/images/tiles/icon-badge.svg'
import IconAvis from '../../../assets/images/tiles/icon-avis.svg'
import IconFlash from '../../../assets/images/tiles/icon-flash.svg'
import IconQuizz from '../../../assets/images/tiles/icon-quizz.svg'
import IconTrophy from '../../../assets/images/tiles/icon-trophy.svg'
import IconPlan from '../../../assets/images/tiles/icon-plan.svg'
import IconPanier from '../../../assets/images/tiles/icon-panier.svg'
import IconAnimation from '../../../assets/images/tiles/icon-animation.svg'
import IconStands from '../../../assets/images/tiles/icon-stands.svg'

import styles from './tiles.module.scss';

export default function Tiles(props){
  const [ tiles, setTiles ] = useState()
  const [ url, setUrl ] = useState()
  const history = useHistory();

  useEffect(()=>{

    let iconCases = {
      "info" : IconInfo,
      "products" : IconOffres,
      "badge" : IconBadge,
      "flash" : IconFlash,
      "quizz" : IconQuizz,
      "trophy" : IconTrophy,
      "panier" : IconPanier,
      "plan" : IconPlan,
      "avis" : IconAvis,
      "animations": IconAnimation,
      "stands" : IconStands
    }

    let arr = props.tiles.map((tile)=>{
      let icon = tile.icon ? iconCases[tile.icon] : null
      let background = tile.background ? tile.background : null
      return (
        {
          ref : React.createRef(),
          title : tile.title,
          url : tile.url ,
          icon ,
          background,
          action : tile.action,
          bold : tile.bold
         }
      )
    })
    setTiles(arr)

  },[props.tiles])

  useEffect(()=>{
    if(url){
      let timer = setTimeout(()=>{
        history.push(url)
        clearTimeout(timer)
      },450)
      return () => {
        clearTimeout(timer)
      }
    }
  },[url])

  function goTo(link,ref){
    if(url) return;
    ref.current.trigger();
    setUrl(link)
  }

  function triggerAction(action,ref){
    action()
    ref.current.trigger();
  }

  return (
    <>
    <div className={styles.tiles}>
      { tiles &&
        <ul>
          {tiles.map((d,i)=>(
            <Tile tile={d} key={`key-${i}`} triggerAction={triggerAction} goTo={goTo}/>
          ))}
        </ul>
      }
    </div>
    </>

  )

}


const Tile = ({tile,triggerAction,goTo}) => {
  return useMemo(()=>{
    return (
      <li className={(tile.url !== "/" || tile.action) ? `${styles.tile} tile` : `${styles.tile} ${styles.active} tile`} >
        <AnimatedTile ref={tile.ref}>
          <a onClick={tile.action ? () =>  triggerAction(tile.action,tile.ref) : () => goTo(tile.url,tile.ref)}
            style={tile.background ? { backgroundImage : `url(${tile.background})`} : {}}
          >
            { tile.icon && <img src={tile.icon} alt={tile.title} />}
            <span className={tile.background ? "top-right" : ""} dangerouslySetInnerHTML={{ __html : tile.title }} />
          </a>
        </AnimatedTile>
      </li>
    )
  },[tile.title])
}
