import React from 'react';

import SupportContact from '../../partials/SupportContact'
import Block from './Block'

import IconTea from '../../../assets/images/icons/icon-tea-cup.svg'
import IconConference from '../../../assets/images/icons/icon-conference.svg'
import IconOpening from '../../../assets/images/icons/icon-opening.svg'
import IconClosing from '../../../assets/images/icons/close.svg'

export default function Horaires (props){
  return(
    <>
      <div className="horaires content large">
        <Block
          isOpen={true}
          title="Mercredi 14 et Jeudi 15 septembre 2022"
        >
          <div>
            <img src={IconTea} alt="café" />
            <p>À partir de 8H30<br/> Café d’accueil</p>
          </div>
          <div>
            <img src={IconConference} alt="conférence" />
            <p>9H00-9H30<br/>Discours d’introduction</p>
          </div>
          <div>
            <img src={IconOpening} alt="porte ouverte" />
            <p>9H00<br/>
            Ouverture du salon</p>
          </div>
          <div>
            <img src={IconClosing} alt="porte fermé" />
            <p>
              Fermeture du salon<br/>
              le <b>14/09</b> à <b>18H</b><br/>
              le <b>15/09</b> à <b>17H</b>
            </p>
          </div>
        </Block>
      </div>
      <div className="support content large">
        <SupportContact breakingPoint={900}/>
      </div>
    </>
  )
}
