import {
  getData,
  postData,
  putData,
  // errorHandler,
} from './index';

import {
  GET_ORDER_BY_ID,
  GET_ORDERS,
  CREATE_ORDER,
  UPDATE_ORDER,
  VALIDATE_ORDER,
  VALIDATE_ORDER_ERROR,
  LOADING_ORDERS,
  ERROR_ORDERS,
} from './types';

export async function getOrders(dispatch, companyId) {
  const url = `/order/company/${companyId}`;

  dispatch({
    type: LOADING_ORDERS,
  });

  let orders;
  await getData(ERROR_ORDERS, url, dispatch, true).then((response) => {
    orders = response.data.orders;
  });

  dispatch({
    type: GET_ORDERS,
    payload: orders,
  });
}

export async function getOrderById(dispatch, id) {
  const url = `/order/${id}`;

  dispatch({
    type: LOADING_ORDERS,
  });

  let order;
  await getData(ERROR_ORDERS, url, dispatch, true).then((response) => {
    order = response.data.order;
  });

  dispatch({
    type: GET_ORDER_BY_ID,
    payload: order,
  });
}

export async function createOrder(dispatch, data) {
  const url = '/order';

  dispatch({
    type: LOADING_ORDERS,
  });

  let order;
  await postData(ERROR_ORDERS, true, url, dispatch, data).then((response) => {
    order = response.order;
  });

  dispatch({
    type: CREATE_ORDER,
    payload: order,
  });
}

export async function updateOrder(dispatch, data, id) {
  const url = `/order/${id}`;

  dispatch({
    type: LOADING_ORDERS,
  });

  let order;
  await putData(ERROR_ORDERS, true, url, dispatch, data).then((response) => {
    order = response.order;
  });

  dispatch({
    type: UPDATE_ORDER,
    payload: order,
  });

  return new Promise((resolve) => {
    resolve();
  });
}

export async function validateOrder(dispatch, data) {
  const url = '/order/validate';

  dispatch({
    type: LOADING_ORDERS,
  });

  let order;
  await postData(VALIDATE_ORDER_ERROR, true, url, dispatch, data).then((response) => {
    order = response.order;
  });

  dispatch({
    type: VALIDATE_ORDER,
    payload: order,
  });
}
