import React, { useState, useEffect } from 'react'
import _ from "lodash";
import { useGlobalContext } from '../../../GlobalContext'
import usePrevious from '../../../../customHooks/usePrevious'

import { updateCompany, updateCompanyPresence } from '../../../../actions/company'

import SelectBoolean from '../../../partials/form_fields/SelectBoolean'
import InputField from '../../../partials/form_fields/InputField'

export default function StoreForm({company,handlePresence, isPresence}){
  const [ context, dispatch ] = useGlobalContext();
  const [ formData, setFormData ] = useState()
  const [ companyMessage, setCompanyMessage ] = useState()

  const [ isPresenceLoading, setIsPresenceLoading ] = useState(false)
  const [ isValidationDisabled , setIsValidationDisabled] = useState(true)
  const companyReducer = context.companyReducer
  const prevSubmit = usePrevious(companyReducer.submitted)

  const options = [ "Oui", "Non"]
  const [ option, selectOption ] = useState(isPresence)

  //ACTIONS
  const _updateCompany = (data,id) => updateCompany(dispatch,data,id)

  useEffect(()=>{
    if(!company) return;
    setFormData({
      centrale : company.centrale,
      acdlec : company.acdlec,
      name : company.name,
      location : {
        city : company.location ? company.location.city : ""
      }
    })
  },[])

  useEffect(()=>{
    if(!prevSubmit && companyReducer.submitted){
      setCompanyMessage("Changement enregistré")
      let timer = setTimeout(()=>{
        setCompanyMessage()
        clearTimeout(timer)
      },2000)

      return () => {
        clearTimeout(timer)
      }

    }
  },[companyReducer.submitted])


  useEffect(()=>{
    if(!formData) return

    if(formData.centrale && formData.acdlec && formData.name &&
      (formData.location && formData.location.city)
    ){
      setIsValidationDisabled(false)
    }else{
      setIsValidationDisabled(true)
    }

  },[formData])

  function handleChange(val,error,field){
    let updatedData = {...formData}
    _.set(updatedData, field, val)
    setFormData(updatedData)
  }

  function submit(){
    if(!isValidationDisabled){
      _updateCompany(formData,company._id)
    }
  }

  async function handleSelectPresence(val){
    setIsPresenceLoading(true)
    await updateCompanyPresence(val,company._id).then((res)=>{
      handlePresence(val)
      selectOption(val)
      setIsPresenceLoading(false)

    }).catch((err) => {
      setIsPresenceLoading(false)
    })
  }


  return (
    <div className="module-identification">
      <p className="section-title">Identification</p>
      <section className="identification">
        <InputField
          value={(company && company.centrale) ? company.centrale : ''}
          title={"Centrale alimentaire"}
          placeholder={""}
          type={"string"}
          required={true}
          disabled={true}
          handleChange={(val,error) => handleChange(val,error,"centrale")}
        />
        <InputField
          value={(company && company.acdlec) ? company.acdlec : ''}
          title={"Code ACDLEC"}
          placeholder={""}
          type={"string"}
          required={true}
          disabled={true}
          handleChange={(val,error) => handleChange(val,error,"acdlec")}
        />
        <InputField
          value={(company && company.name) ? company.name : ''}
          title={"Raison sociale"}
          placeholder={""}
          type={"string"}
          required={true}
          disabled={true}
          handleChange={(val,error) => handleChange(val,error,"name")}
        />
        <InputField
          id={"city"}
          value={(company && company.location && company.location.city) ?
            company.location.city : ""
          }
          title={"Ville"}
          autocomplete={"disabled"}
          placeholder={""}
          type={"string"}
          required={true}
          disabled={true}
          handleChange={(val,error) => handleChange(val,error,"location.city")}
        />
        <div className="container-submit">
          <div className="container-feedback">
            <button onClick={submit} className={isValidationDisabled ? "disabled" : ""}>
              Valider
              {companyReducer.isLoading ?
                <div className="loader"></div> : null
              }
            </button>
            {
              companyMessage ? <p className="succeed">{companyMessage}</p> : null
            }
          </div>
        </div>
      </section>
      <div className="container-select-presence">
        <p>
          L’Adhérent ou le Directeur confirme la présence du magasin au salon
        </p>
        <SelectBoolean
          items={options}
          bool={option}
          nullable
          handleChange={(val) => handleSelectPresence(val)}
        />
        { isPresenceLoading ?
          <div className="loader"></div> : null
        }
        { !option ?
          <p>
            Etes-vous certain de ne pouvoir participer à cet évènement ? Vous avez jusqu'au 31 août pour vous inscrire
          </p> : null
        }
      </div>
    </div>
  )
}
