import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-reader'
import { useGlobalContext } from '../../../GlobalContext'
import { validateOrder } from '../../../../actions/orders'
import { NavLink } from 'react-router-dom'
import ButtonClose from '../../../partials/Close'

import ImageConfirmation from '../../../../assets/images/confirmation-animation.svg'
import ImageErrorOrder from '../../../../assets/images/error-order.svg'

export default function ValidationOrder({orderId, mobile, triggerClose}){
  const [ context, dispatch ] = useGlobalContext()
  const [ userId, setUserId ] = useState();
  const productsReducer = context.productsReducer
  const [ validate, setValidate ] = useState(null);
  //ACTIONS
  const _validateOrder = (data) => validateOrder(dispatch,data)

  useEffect(()=>{
    if(productsReducer.confirmedOrder === `order-${orderId}`){
      setValidate(true)
      dispatch({type : "RESET_CONFIRMED_ORDER"})
    }else if(productsReducer.confirmedOrder === `order-failed`){
      setValidate(false)
      dispatch({type : "RESET_CONFIRMED_ORDER"})
    }

    if(productsReducer.confirmedOrder){
      return () => dispatch({type : "RESET_CONFIRMED_ORDER"})
    }
  },[productsReducer.confirmedOrder])

  function submitOrder(){
    let obj = {
      user: userId,
      order: orderId
    }
    _validateOrder(obj)
  }

  function activeScan(){
    setUserId()
    setValidate(null)
  }

  function handleScan(data) {
    if (data) setUserId(data)
  }

  function handleError (err) {
    console.error(err)
  }

  function getClassName(){
    let done = "";
    if(validate !== null) done = 'done'
    return mobile ? "validate-order mobile "+done : "validate-order "+done
  }

  function close(){
    if(!validate) triggerClose()
  }

  return (
    <div className={getClassName()}>

      <div className="overlay" onClick={close}></div>
      <div className="modal">
        {(triggerClose && !validate) &&
           <ButtonClose closingAction={triggerClose}/>
        }
        {(!triggerClose && !validate) &&
           <NavLink to={`/order/${orderId}`}><button>Retour</button></NavLink>
        }

        { validate === null &&
          <div className="scanner">
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%', height:"100%" }}
            />
            {userId &&
              <div className="container-btn submit">
                <button onClick={submitOrder}>Valider la commande</button>
              </div>
            }
          </div>
        }

        { validate === true &&
          <div className="result-validate">
            <p>Votre commande a bien été effectuée !</p>
            <img src={ImageConfirmation} alt="commande validée avec succées"/>
            <NavLink to={`/orders`}><button>Retour aux commandes</button></NavLink>

          </div>
        }

        { validate === false &&
          <div className="result-validate">
            <p>Oops ! Quelque chose s'est mal passé...</p>
            <img src={ImageErrorOrder} alt="la commande n'a pas pu être validée"/>
            <button className="blue" onClick={activeScan} >Scanner à nouveau</button>
          </div>
        }

        {!userId &&
          <div className="container-btn">
            <button className="blue">Scanner le QRCode du fournisseur</button>
          </div>
        }
      </div>
    </div>
  )
}
