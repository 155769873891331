import React from "react";
import LogoLeclercMobile from '../../../assets/images/logo-leclerc-mobile.svg'

export default function HeaderMobileTopBar({isMenuProfileOpen, setIsMenuProfileOpen, closeNavigation}){
  return (
    <div className="mobile-top-bar">
      {isMenuProfileOpen ?
        <button onClick={()=>setIsMenuProfileOpen(false)}>Retour</button> :
        <div className="logo">
          <img src={LogoLeclercMobile} alt="logo leclerc" />
          <h2>{process.env.REACT_APP_NAME}</h2>
        </div>
      }
      <div className="close" onClick={closeNavigation}></div>
    </div>
  )
}
