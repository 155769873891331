export function formatDataShowrooms(data){


  const powerQuantity = parseInt(data?.option?.powerQuantity, 10);

  let countChair = typeof data.option.chair === 'number' ? data.option.chair :data.option.countChair;
  let countTable = typeof data.option.table === 'number' ? data.option.table :data.option.countTable;
  let countFridge = typeof data.option.fridge === 'number' ? data.option.fridge :data.option.countFridge;
  const fridgeLength = data.option.fridgeLength ? parseFloat(data.option.fridgeLength) : 0;

  countChair = parseInt(countChair, 10);
  countTable = parseInt(countTable, 10);
  countFridge = parseInt(countFridge, 10);

  let showroomData = {
    "dimension":data.dimension,
    "company":data.company,
    "user":data.user,
    "option":{
      "chair": !Number.isNaN(countChair) ? countChair : 0,
      "table": !Number.isNaN(countTable) ? countTable : 0,
      "fridge": !Number.isNaN(countFridge) ? countFridge : 0,
      fridgeLength: !Number.isNaN(fridgeLength) ? fridgeLength : 0,
      "power": data.option.power,
      "powerQuantity": !Number.isNaN(powerQuantity) ? powerQuantity : 0,
      "standiste":{
        "name":"",
        "phone":"",
        "email":""
      }
    }
  }
  

  if(data.option.standiste){
    showroomData.option.standiste = {
      name : data.option.standiste.name ? data.option.standiste.name : "",
      phone : data.option.standiste.phone ? data.option.standiste.phone : "",
      email : data.option.standiste.email ? data.option.standiste.email : ""
    }
  }

  return showroomData

}

export function calculPrice(data){
  let obj = {}
  let total = 0

  if(data.option &&
    data.option.direction &&
    data.dimension
  ){
    let dimension = data.dimension
    total = (dimension/6) * 5400
  }

  if(data.option &&
    data.option.customDimension &&
    data.option.customDimension.l &&
    data.option.customDimension.L
  ){
    let dimension = data.option.customDimension.l*data.option.customDimension.L
    // (dimension%6) * 1000 // supplement de 1000€ par 1m
    // (Math.floor(dimension/6))*4500 // supplement de 1000€ par 1m²
    total =  ((Math.floor(dimension/6))*5400) + (dimension%6) * 1000
  }

  obj.totalNoOptions = total
  obj.totalOptions = 0

  if(data.option &&
    data.option.storageSize){
    obj.storagePrice = data.option.storageSize*45
    obj.totalOptions += obj.storagePrice
    total = total + (data.option.storageSize*45)
  }

  if(data.option &&
    data.option.power){
    total = total + 230
    obj.powerPrice = 230
    obj.totalOptions += obj.powerPrice
  }

  if(data.option &&
    data.option.water){
    total = total + 330
    obj.waterPrice = 330
    obj.totalOptions += obj.waterPrice
  }

  obj.total = total
  return obj

}
