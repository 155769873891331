import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { NavLink, useHistory } from 'react-router-dom'
import { useGlobalContext } from '../../../GlobalContext'
import { getProducts } from '../../../../actions/products'
import { updateOrder } from '../../../../actions/orders'
import useDimension from '../../../../customHooks/useDimension'

import { getProductsInShoppingList } from '../../../../helpers/helpers'

import Loader from '../../../partials/Loader'
import InputField from '../../../partials/form_fields/InputField'
import { fnFormat } from '../../../../helpers/helpers'

import ListProducts from './ListProducts'

import IconSearch from '../../../../assets/images/icons/icon-search.svg'

export default function Products() {
  const [context, dispatch] = useGlobalContext()
  const productsReducer = context.productsReducer
  const history = useHistory();
  const dimension = useDimension();

  const [positionFilters, setPositionFilters] = useState()
  const [filtersFixed, setFiltersFixed] = useState()

  const [allProducts, setAllProducts] = useState()
  const [filteredProducts, setFilteredProducts] = useState()

  const [sort, setSort] = useState()
  const [search, setSearch] = useState()

  const [validateOrders, setValidateOrders] = useState()

  //ACTIONS
  const _getProducts = () => getProducts(dispatch)
  const _updateOrder = (order, id) => updateOrder(dispatch, order, id)

  useEffect(() => {
    let target = document.querySelector('.filters')
    if (target) setPositionFilters(target.offsetTop)
  }, [filteredProducts, dimension.width])

  useEffect(() => {
    if (!productsReducer.orders) return;
    let allProducts = [];
    let countedProducts = [];

    let validateOrders = [];

    productsReducer.orders.forEach((order) => {
      if (order.status === "pending") {
        let orderId = order._id;
        let countBy = _.countBy(order.products, "_id");

        allProducts.push(...order.products);
        for (let id in countBy) {
          let findProduct = order.products.find((d) => d._id === id);
          countedProducts.push({ ...findProduct, orderId, count: countBy[id] });
        }

      } else if (order.status === "confirmed") {
        validateOrders.push(order)
      }
    })

    let groupedProducts = groupProductsByProviders(countedProducts);
    setFilteredProducts(groupedProducts);
    setAllProducts(countedProducts);
    setValidateOrders(validateOrders);

  }, [productsReducer.orders]);


  //SORTING PRODUCTS
  useEffect(() => {
    if (!allProducts) return;
    let products = _.cloneDeep(allProducts)

    if (search) {
      products = products.filter(product => {
        return (
          (product.ref && product.ref.toLowerCase().indexOf(search) >= 0) ||
          (product.type && product.type.toLowerCase().indexOf(search) >= 0) ||
          (product.company.name && product.company.name.toLowerCase().indexOf(search)) >= 0
        )
      })
    }

    let groupedProducts = groupProductsByProviders(products)

    if (sort && !_.isEmpty(sort)) {
      let arrKeys = Object.keys(sort)
      arrKeys.map((key, i) => {
        let findSort = sort[key]
        groupedProducts[key] = _.orderBy(groupedProducts[key], findSort.key,
          [findSort.order,
          (d) => new Date(d.updatedAt).getTime()
          ])
      })
    }

    setFilteredProducts(groupedProducts)

  }, [allProducts, search, sort])

  function groupProductsByProviders(products) {
    let groupedProducts = _.groupBy(products, "company._id");
    let sortedGroup = {}
    let arrKeys = Object.keys(groupedProducts).sort()

    arrKeys.forEach((key, i) => {
      sortedGroup[key] = _.sortBy(groupedProducts[key], (d) => new Date(d.updatedAt).getTime())
    })

    return sortedGroup
  }

  function handleSearch(val) {
    let value = val.toLowerCase();
    setSearch(value)
  }

  function sortProducts(obj, key) {
    let objSort = { ...sort }
    if (_.isEmpty(obj)) {
      delete objSort[key]
    } else {
      objSort[key] = obj
    }
    setSort(objSort)
  }

  function cancelOrder(orderId) {
    let r = window.confirm("Vous allez modifier une commande en cours avec ce fournisseur. Vous allez devoir la valider à nouveau en scannant le badge du fournisseur. Etes-vous sûr ?");
    if (r == true) {
      let obj = {
        status: "pending"
      }
      _updateOrder(obj, orderId).then(() => {
        history.push(`/order/${orderId}`)
      })
    }
  }

  function showDetailOrder(orderId) {
    history.push(`/order/detail/${orderId}`);
  }

  function scroll(e) {
    let target = document.querySelector('.filters')

    if (!filtersFixed &&
      e.target.scrollTop >= positionFilters
    ) {
      setFiltersFixed(true)
      target.classList.add('fixed')
    } else if (
      filtersFixed &&
      e.target.scrollTop < positionFilters
    ) {
      setFiltersFixed(false)
      target.classList.remove('fixed')
    }
  }

  function renderPendingOrders() {
    let arrKeys = Object.keys(filteredProducts)
    let listProducts = arrKeys.map((key, i) => {

      let products = filteredProducts[key]
      if (products[0]) {
        let countProductsByCompany = allProducts.filter((d) => d.company._id === key)
        let total = getTotal(countProductsByCompany)
        let orderId = products[0].orderId
        return (
          <React.Fragment key={`list-${key}-${i}`}>
            <div className="title-provider" >
              <div>
                <p>{products[0].company.name}</p>
                <p className="price hide-on-tablet">{fnFormat(total)}€</p>
              </div>
              <div>
                <NavLink to={`order/${orderId}`} >
                  <button>Valider cette commande</button>
                </NavLink>
                <p className="price only-on-tablet">{fnFormat(total)}€</p>
              </div>

            </div>
            <ListProducts sort={(sort && sort[key]) ? sort[key] : {}} sortProducts={(obj) => sortProducts(obj, key)} products={products} />
          </React.Fragment>
        )
      } else {
        return null
      }

    }
    )

    return listProducts;

  }

  function renderConfirmedOrders() {
    let orders = validateOrders.map((order) => {
      let total = order.products.map(product => {
        const price = product.price;
        return price * parseInt(product.packing)
      }).reduce((total, product) => total + product)
      return (
        <div className="confirmed-order" key={`order-${order._id}`}>
          <p><span>{order.products[0].company.name}</span> <span className="total-tablet"> {fnFormat(total)}€</span></p>
          <div>
            <a onClick={() => cancelOrder(order._id)}>
              <button>Modifier la commande</button>
            </a>
            <a onClick={() => showDetailOrder(order._id)}>
              <button>Voir la commande</button>
            </a>
            <p className="hide-on-mobile">{fnFormat(total)}€</p>
          </div>
        </div>
      )
    })

    if (orders.length > 0) {
      return [
        <div key={`title-order`} className="title-validate"><h3>Commande(s) validée(s)</h3></div>,
        orders
      ]
    } else {
      return null
    }


  }

  function getTotal(products) {
    let total = 0;
    products.forEach((d) => {
      const price = d.price;
      if (!isNaN(parseInt(d.packing))) {
        total += (price * parseInt(d.packing)) * d.count
      } else {
        total += price * d.count
      }

    })
    return total
  }

  function panierNumber(orders) {
    if (!orders) return
    let numberColis = 0;

    const reducer = (accumulator, currentValue) => accumulator + parseInt(currentValue.packing);

    orders.forEach((order) => {
      let arrPacking = order.products.map((d) => {
        return isNaN(parseInt(d.packing)) ? 1 : parseInt(d.packing)
      })
      let total = arrPacking.reduce((accumulator, currentValue) => accumulator + currentValue)
      numberColis += total
    })

    if (numberColis > 0) {
      return numberColis
    } else {
      return null
    }
  }

  function shouldBeHide(){
    let bool;
    if(!productsReducer.orders) bool = true;
    else bool = !productsReducer.orders.some((order) => order.status === 'pending');
    return bool;
  }

  return (
    <>
      <div className="orders" onScroll={scroll}>
        <div className="content large">
          <div className="title-page">
            <h2>
              Mon panier
              {(productsReducer.orders && productsReducer.orders.length > 0) && <> ({panierNumber(productsReducer.orders)})</>}
            </h2>
          </div>
          { !shouldBeHide() && 
            <div className="filters">
            <div className="field-search">
              <InputField
                id={"search"}
                title={""}
                placeholder={"Recherche"}
                type={"string"}
                required={false}
                handleChange={(val) => handleSearch(val)}
              />
              <img src={IconSearch} alt="rechercher produits" />
            </div>
          </div>
          }
          {filteredProducts
            ? <>
              {renderPendingOrders()}
            </>
            : <div className="loader"><Loader /></div>
          }
          {validateOrders && renderConfirmedOrders()}
        </div>
      </div>
    </>
  )
}
