import React, { useState, useEffect, useMemo, useRef } from "react"
import _ from 'lodash'
import { useGlobalContext } from '../../../GlobalContext'
import {  updateOrder } from '../../../../actions/orders'
import LineProduct from './LineProduct'

export default function ListProducts({products, sortProducts, sort, readOnly = false}){
  const isMounted = useRef()
  const [ context, dispatch ] = useGlobalContext()
  const authReducer = context.authReducer
  const productsReducer = context.productsReducer

  //ACTIONS
  const _updateOrder = (order,id) => updateOrder(dispatch,order,id)

  function fnUpdateOrder(orderID,product,count){
    let order = productsReducer.orders.find((order) => order._id === orderID)
    let updateOrder = _.cloneDeep(order)

    updateOrder.products = updateOrder.products.filter((p)=> p._id !== product._id)
    updateOrder.products = [ ...updateOrder.products, ...new Array(count).fill(product,0,count)]

    let obj = {
      products: updateOrder.products,
      company : authReducer.user.company,
      user : authReducer.user._id
    }

    _updateOrder(obj,orderID)

  }

  function fnSortProducts(key,order){
    let obj = {}
    obj.key = key
    let updatedSort;
    if(!order){
      if(sort.key === key && sort.order === "desc"){
        updatedSort = {}
      }else if(sort.key === key && sort.order === "asc"){
        obj.order = "desc"
        updatedSort = obj
      }else if(sort.key !== key){
        obj.order = "asc"
        updatedSort = obj
      }
    }else{
      if(sort.key === key && sort.order === order){
        updatedSort = {}
      }else{
        obj.order = order
        updatedSort = obj
      }
    }

    sortProducts(updatedSort)

  }

  function getTotal(product){
    let total = 0;
    let price = product.price;
    if(!isNaN(parseInt(product.packing))){
      total+= (price*parseInt(product.packing))*product.count
    }else{
      total+= price*product.count
    }
    return total
  }


  function renderListProducts(){
    let list = products.map((product,i)=>{
      return (
        <li key={`products-${i}`}>
          <LineProduct
            readOnly={readOnly}
            updateOrder={fnUpdateOrder}
            product={{...product, total : getTotal(product)}}
            count={product.count}
          />
        </li>
      )
    })

    return (
      <ul>
        {list}
      </ul>
    )

  }

  return useMemo(()=>{
    return (
      <div className="list-products">
        <div className="sortBy hide-on-tablet">
          <div>
            <p onClick={() => fnSortProducts("product")}>Famille de produit</p>
            <SortBy keyName={"product"} order={sort.key === "product" && sort.order} sort={sortProducts}/>
          </div>
          <div className="product-ref">
            <p onClick={() => fnSortProducts("ref")}>Référence</p>
            <SortBy keyName={"ref"} order={sort.key === "ref" && sort.order} sort={sortProducts}/>
          </div>
          <div className="product-price">
            <p onClick={() => fnSortProducts("price")}>Prix net salon HT/unité</p>
            <SortBy keyName={"price"} order={sort.key === "price" && sort.order} sort={sortProducts}/>
          </div>
          <div className="product-segment">
            <p onClick={() => fnSortProducts("segment")}>Segment</p>
            <SortBy keyName={"segment"} order={sort.key === "segment" && sort.order} sort={sortProducts}/>
          </div>
          <div className="product-code">
            <p onClick={() => fnSortProducts("gencod")}>Gencod</p>
            <SortBy keyName={"gencod"} order={sort.key === "gencod" && sort.order} sort={sortProducts}/>
          </div>
          <div className="product-packing">
            <p onClick={() => fnSortProducts("packing")}>Colisage</p>
            <SortBy keyName={"packing"} order={sort.key === "packing" && sort.order} sort={sortProducts}/>
          </div>
          <div className="product-qty">
            <p>Nbre de colis</p>
          </div>
          <div className={readOnly ? "product-total readonly" :  "product-total"}></div>
        </div>
        {renderListProducts()}
      </div>
    )
  },[products,sort])
}

const SortBy = ({sort,order,keyName}) => {
  return (
    <div className="icon-sort" >
      <div className={order === "desc" ? "active" : ""} onClick={() => sort(keyName,"desc")}>
      </div>
      <div className={order === "asc" ? "active" : ""} onClick={() => sort(keyName,"asc")}>
      </div>
    </div>
  )
}
