import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { useGlobalContext } from '../../GlobalContext'
import useDimension from '../../../customHooks/useDimension'
import { getBadge } from '../../../actions/users'

import SupportContact from '../../partials/SupportContact'
import ModalSupportContact from '../../partials/ModalSupportContact'

import Slider from '../../partials/Slider/Slider'
import Tiles from '../../partials/Tiles/Tiles'
import Flash from '../../partials/Flash'
import Loader from '../../partials/Loader'

import poster from '../../../assets/images/poster.png'

import IconTrophy from '../../../assets/images/trophy.svg'
import IconQuizz from '../../../assets/images/quizz.svg'

import IconApp from '../../../assets/images/icons/icon-application.svg'
import PlanSalon from '../../../assets/images/plan-salon.png'
import PlanSalon2 from '../../../assets/images/plan-salon2.png'

import styles from './salon.module.scss';

export default function Salon() {
  const context = useGlobalContext()[0];
  const history = useHistory();
  const authReducer = context.authReducer;
  const companyReducer = context.companyReducer;
  const productsReducer = context.productsReducer;
  const { exhibition } = authReducer;
  const [tiles, setTiles] = useState();
  const [tilesProviders, setTilesProviders] = useState();

  const dimension = useDimension();

  useEffect(() => {
    let orders = "Mon panier"
    if (productsReducer.orders && productsReducer.orders.length) {
      orders = `Mon panier (${panierNumber(productsReducer.orders)})`
    }

    let tiles = [
      // { title: "Liste des exposants", url: "/list-providers", icon: "stands" },
      // { title: "Animations et<br/>conférences", url: "/programme", icon: "animations" },
      // { title: "Informations<br/>pratiques", url: "/informations/", icon: "info" },
      //   {title : "Mon badge", url : "/" , icon : "badge"},
      // {
      //   title: "Le plan", url: "/", icon: null, background: PlanSalon2,
      //   action: () => history.push('/plan')
      // },
    ]

    if (companyReducer.company && companyReducer.company?.domains?.length > 0) {
      tiles.splice(2, 0, { title: "Offres salon", url: "/products?page=1", icon: "products" })
      tiles.splice(3, 0, { title: orders, url: "/orders", icon: "panier" })
    }

    let tilesProviders = [
      // { title: "Informations<br/>pratiques", url: "/informations/", icon: "info" },
      // {
      //   title: "Le plan", url: "/", icon: null, background: PlanSalon2,
      //   action: () => history.push('/plan')
      // },
      // { title: "Questionnaire de<br/>satisfaction", url: "/avis", icon: "avis" }
    ]

    // if(companyReducer.company && companyReducer.company.activity !== "marque"){
    tilesProviders.splice(3, 0, { title: "Mes produits", url: "/products-provider", icon: "panier" })
    // }

    setTilesProviders(tilesProviders)
    setTiles(tiles)

  }, [productsReducer.orders]);

  function panierNumber(orders) {
    if (!orders) return
    let numberColis = 0;

    const reducer = (accumulator, currentValue) => accumulator + parseInt(currentValue.packing);

    orders.forEach((order) => {
      let arrPacking = order.products.map((d) => {
        return isNaN(parseInt(d.packing)) ? 1 : parseInt(d.packing)
      })
      let total = arrPacking.reduce((accumulator, currentValue) => accumulator + currentValue)
      numberColis += total
    })

    if (numberColis > 0) {
      return numberColis
    } else {
      return null
    }
  }

  let sliderItems = [
    <div className={`${styles['slider-item']} ${styles.trophy}`}>
      <NavLink to="/trophy">
        <img src={IconTrophy} alt="trophée" />
        <p>Trophées du plus beau stand</p>
      </NavLink>
    </div>,
  ];

  //   <div className="slider-item quizz">
  //   <NavLink to="/quizz">
  //     <img src={IconQuizz} alt="quizz" />
  //     <p>Quiz</p>
  //   </NavLink>
  // </div>

  const renderImage = () => {
    if (!exhibition?.cover?.path) return null;
    const token = localStorage.getItem('token')?.replace('JWT ', '');
    const url = `${process.env.REACT_APP_API_URL}/files/${exhibition?.cover?.path.replace('upload/', '')}?token=${token}`
    return (
      <div className={styles.col}>
        <div className={styles.cover}>
          <img src={url} />
        </div>
      </div>
    )
  }

  return (
    <>
      <ModalSupportContact />
      <div className={styles.salon}>
        <div className={`${styles.content} content large`}>
          <div className={styles['container-col']}>
            <div className={styles.col}>
              {(companyReducer.company && companyReducer.company.type === "magasin" && dimension.width < 1024) &&
                <div>
                  {/* <Slider components={sliderItems} /> */}
                  {tiles && <Tiles tiles={tiles} />}
                </div>
              }

              {(companyReducer.company && companyReducer.company.type === "fournisseur" && dimension.width < 1024) &&
                <div>
                  {tiles && <Tiles tiles={tilesProviders} />}
                </div>
              }

              {/*
              { (((companyReducer.company && companyReducer.company.type === "magasin") ||
                (companyReducer.company && companyReducer.company.type === "fournisseur")) &&
                dimension.width >= 1024) &&
            */}
              {((companyReducer.company && companyReducer.company.type === "magasin") ||
                (companyReducer.company && companyReducer.company.type === "fournisseur")) &&
                <div>
                  <h2>{process.env.REACT_APP_NAME}</h2>
                  {exhibition?.name && <h1>{exhibition.name}</h1>}
                  {exhibition?.description && 
                    <div dangerouslySetInnerHTML={{ __html: exhibition?.description }} />
                  }
                  <div className={styles['mobile-centered']}>
                    {authReducer?.user?.role !== "Member" && companyReducer.company.type === "magasin" ?
                      <NavLink to={"/store/inscription"}>
                        <button>
                          Inscrire mon magasin
                        </button>
                      </NavLink> : null
                    }
                  </div>

                </div>
              }

            </div>
            {renderImage()}
          </div>
          <SupportContact
            className={styles['support-contact']}
            breakingPoint={900}
          />

        </div>
      </div>
    </>
  )
}
