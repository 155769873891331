import React, { useContext, useEffect } from "react";
import { useGlobalContext } from '../../GlobalContext'
import ModalContext from '../../ModalContext';
import IconBluePhone from '../../../assets/images/icons/phone_blue_icon.svg';
import IconMailBlue from '../../../assets/images/icons/mail_blue_icon.svg';
import IconPhone from '../../../assets/images/icons/phone_icon.svg';
import useDimension from '../../../customHooks/useDimension';

import styles from './support-contact.module.scss';

export default function SupportContact({
  className = '',
  breakingPoint = 1124,
  pageForm,
}){
  const context = useGlobalContext()[0];
  const modalContext = useContext(ModalContext);
  const authReducer = context.authReducer;
  const { exhibition } = authReducer;

  const dimension = useDimension();

  useEffect(()=>{
    if (dimension.width > breakingPoint && modalContext.isModalOpen) {
      modalContext.setIsModalOpen(false);
    }
  },[dimension.width]);

  return (
    <div className={`${styles['support-contact']} ${className}`}>
      {pageForm ?
        <p className="required"><span >*</span> données obligatoires</p> : null
      }
      { dimension.width < breakingPoint  ?
        <button className="btn-blue" onClick={() => modalContext.setIsModalOpen(!modalContext.isModalOpen)}>
          <img src={IconPhone} alt="numéro de téléphone" />
        </button> :
        <>
          <h3>Une question ?</h3>
          <p>{exhibition?.contact?.name || ''}</p>
          <p className={styles.phone}>
            <img src={IconBluePhone} alt="numéro de téléphone" />
            <a href="tel:02-40-85-10-10">{exhibition?.contact?.tel || ''}</a>
          </p>
          <p className={styles.email}>
            {exhibition?.contact?.email && (
              <>
                <img src={IconMailBlue} alt="adresse email" />
                <a href="mailto:name@email.com">{exhibition?.contact?.email || ''}</a>
              </>
            )}
          </p>
        </>
      }
    </div>
  );
};

