import React, { useEffect, useState } from 'react'
import _ from "lodash";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Switch from "react-switch";
import Select from 'react-select';
import { useGlobalContext } from '../../../../GlobalContext'
import usePrevious from '../../../../../customHooks/usePrevious'
import useDispatch from '../../../../../customHooks/useDispatch'
import useDimension from '../../../../../customHooks/useDimension'

import { updateCompanyUser, addCompanyUser, resetCompanyError } from '../../../../../actions/company'
import InputField from '../../../../partials/form_fields/InputField'
import SelectItems from '../../../../partials/form_fields/SelectItems'

import selectStyle from '../../../../partials/form_fields/selectStyle'

export default function FormContact({ activities, user, isLoading, cancel }) {
  const [formData, setFormData] = useState({})
  const [optionsActivities, setOptionsActivities] = useState()
  const [isLunchChecked, setIsLunchChecked] = useState(false)
  const [selectedPresenceDays, setSelectedPresenceDays] = useState([])
  const [selectedActivities, setSelectedActivities] = useState([])
  const [isValid, setIsValid] = useState(false)
  const [presenceDays, setPresenceDays] = useState()

  const [context, dispatch] = useGlobalContext();
  const companyReducer = context.companyReducer;

  const wasLoading = usePrevious(isLoading)
  const dimension = useDimension()

  const [isEmailError, setIsEmailError] = useState(false)

  //ACTIONS
  const _updateCompanyUser = (data, id) => updateCompanyUser(dispatch, data, id)
  const _addCompanyUser = (data) => addCompanyUser(dispatch, data)
  const _resetCompanyError = useDispatch(resetCompanyError, dispatch)

  // HANDLE ERROR ON EMAIL
  useEffect(() => {
    setIsEmailError(/email/.test(companyReducer.error))
  }, [companyReducer.error])

  // SET ACTIVITIES OPTIONS
  useEffect(() => {
    let options = activities.map((d) => (
      { label: d.label, value: d.label }
    ))
    options.push({ label: "AUCUN", value: null })
    setOptionsActivities(options)
    // eslint-disable-next-line
  }, [])


  // RESET COMPANY ERROR WHEN UNMOUNT
  useEffect(() => {
    return () => {
      _resetCompanyError()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const getDateStr = (date) => {
      const day = format(new Date(date), 'd MMMM', { locale: fr });
      return day;
    }
    const days = companyReducer.companyList.days.map((day) => ({ value: day.value, label: getDateStr(day.value) } ));
    setPresenceDays(days);
  }, []);


  // INIT ACTIVITIES
  // IF THE ARE AN USER
  useEffect(() => {
    if (!user) return;
    if (user.profile.activity) {
      let activities = user.profile.activity.map((d) => {
        if (d === null) {
          return { label: "AUCUN", value: null };
        } else {
          return { label: d, value: d };
        }
      });
      setSelectedActivities(activities);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!user || !presenceDays) return;

    if (user.presence) {
      setSelectedPresenceDays(user.presence)
    }
    setIsLunchChecked(user.lunch);


  }, [presenceDays])

  // CLOSE FORM CONTACTS AFTER SUBMIT
  useEffect(() => {
    if (wasLoading && !isLoading) {
      cancel()
    }
  }, [isLoading])

  // HANDLE IS READY TO SUBMIT
  useEffect(() => {
    
    let emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i
    let data;

    // SUBMIT UPDATE USER
    if (user) {

      let sanitizeUser = user.email.split("//")[0]
      let initialProfile = {
        profile: user.profile,
        email: sanitizeUser,
        presence: user.presence
      }
      data = _.merge(
        {},
        initialProfile,
        formData
      );
    }

    // SUBMIT CREATE USER
    if (!user) {
      data = {
        profile: formData.profile,
        email: formData.email,
        presence: formData.presence
      }
    }

    if (
      (data.profile &&
        (data.profile.lastName && data.profile.lastName !== "") &&
        (data.profile.firstName && data.profile.firstName !== "") &&
        (data.profile.function && data.profile.function !== "") &&
        (data.profile.activity && data.profile.activity.length > 0)
      ) && (
        data.email &&
        emailReg.test(data.email)
      ) && (data.presence)) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }

  }, [formData])

  function handleChange(val, error, field) {
    let updatedData = { ...formData }

    if (field === "profile.activity") {
      setSelectedActivities(val ? val : [])
    }

    if (field === "presence" && val) {
      let found = presenceDays.filter(day => val.includes(day.label));
      let updatedDays = found.map((d) => d.value);
      setSelectedPresenceDays(updatedDays);
      _.set(updatedData, field, updatedDays);
    } else {
      _.set(updatedData, field, val);
    }

    setFormData(updatedData)
  }

  function submit() {
    if (!isValid || companyReducer.isLoading) return;
    if (user) {
      submitUdpateUser()
    } else {
      submitCreateUser()
    }
  }

  function submitUdpateUser() {

    let initialData = {
      profile: user.profile,
      presence: user.presence
    }
    delete initialData.profile.activity

    var mergedData = _.merge(
      {},
      initialData,
      formData
    );

    mergedData.company = user?.company?.map((d) => d._id) || [];
    mergedData.profile.activity = selectedActivities.map((d) => d.value);

    /// HANDLE PRESENCE DAYS
    // PREVENT ISSUE CAUSED BY MERGING presence day of different length
    if (formData.presence) mergedData.presence = formData.presence;
    mergedData.presence = mergedData.presence.filter(d => !!d);

    _updateCompanyUser(mergedData, user._id)

  }

  function submitCreateUser() {
    let obj = _.cloneDeep(formData)
    obj.company = companyReducer.company._id

    /// HANDLE PRESENCE DAYS
    obj.presence = obj.presence.filter(d => !!d);

    if (obj.profile.activity) {
      obj.profile.activity = obj.profile.activity.map((d) => d.value).filter(d => !!d);
    }
    obj.type = companyReducer.company.type
    _addCompanyUser(obj)

  }

  function toggleLunch(val) {
    let updatedData = { ...formData }
    _.set(updatedData, "lunch", val)
    setFormData(updatedData)
    setIsLunchChecked(val)
  }

  function getSelectedItems() {
    let found = presenceDays.filter(day => selectedPresenceDays.includes(day.value))
    return (found && found.length > 0) ? found.map(d => d.label) : []
  }

  return (
    <MobileWrapper isMobile={dimension.width < 767}>
      <section className={dimension.width > 767 ? "module-contact" : "module-contact mobile"}>

        {dimension.width < 767 ?
          <h2>
            Inscription d’un collaborateur
          </h2> : null
        }
        <InputField
          id={user ? "lastName_" + user._id : "lastName"}
          value={user ? user.profile.lastName : ""}
          title={"Nom"}
          placeholder={""}
          type={"string"}
          textTransform={"uppercase"}
          required={true}
          disabled={false}
          handleChange={(val, error) => handleChange(val, error, "profile.lastName")}
        />
        <InputField
          id={user ? "firstName_" + user._id : "firstName"}
          value={user ? user.profile.firstName : ""}
          title={"Prénom"}
          placeholder={""}
          type={"string"}
          textTransform={"capitalized"}
          required={true}
          disabled={false}
          handleChange={(val, error) => handleChange(val, error, "profile.firstName")}
        />
        <InputField
          id={user ? "function_" + user._id : "function"}
          value={user ? user.profile.function : ""}
          title={"Fonction"}
          placeholder={""}
          type={"string"}
          textTransform={"uppercase"}
          required={true}
          disabled={false}
          maxLength={"30"}
          handleChange={(val, error) => handleChange(val, error, "profile.function")}
        />
        <div className="container-email">
          <InputField
            id={user ? "email_" + user._id : "email"}
            value={user ? user.email.split("//")[0] : ""}
            title={"Email"}
            placeholder={""}
            type={"string"}
            format={"email"}
            required={true}
            disabled={user ? true : false}
            handleChange={(val, error) => handleChange(val, error, "email")}
          />
          {isEmailError && companyReducer.error ?
            <p className="warn">{companyReducer.error}</p> : null
          }
        </div>
        <div className="container-input">
          <label>Lieu de vente<span className="required">*</span></label>
          <Select
            onChange={(val) => handleChange(val, null, "profile.activity")}
            options={optionsActivities}
            value={selectedActivities}
            isMulti={true}
            isSearchable={false}
            styles={selectStyle}
          />
        </div>
        <div className="container-input container-select-items">
          <label>Présence sur le salon <span className="required">*</span></label>
          {(presenceDays && presenceDays.length > 0) &&
            <SelectItems
              items={presenceDays.map(day => day.label)}
              selectedItems={getSelectedItems()}
              required={true}
              uniq={false}
              handleChange={(val, error) => handleChange(val, error, "presence")}
            />
          }
        </div>
        <div className="container-switch">
          <p>
            Présence au déjeuner
            <span className="required">*</span>
          </p>
          <Switch
            onColor='#080'
            offColor='#FF0009'
            onChange={toggleLunch}
            checked={isLunchChecked}
          />
        </div>
        <div className="container-button">
          <button className="secondary" onClick={cancel}>Fermer</button>
          <button className={!isValid ? "disabled" : ""} onClick={submit}>
            {user && user._id ?
              "Modifier" : "Ajouter"
            }
            {companyReducer.isLoading ?
              <div className="loader"></div> : null
            }
          </button>
        </div>
        {!isEmailError && companyReducer.error ?
          <p className="warn">{companyReducer.error}</p> : null
        }
      </section>
    </MobileWrapper>
  )
}

const MobileWrapper = ({ isMobile, wrapper, children }) => {
  return (
    isMobile ?
      <div className="container-mobile">
        <div className="overlay-module-contact"></div>
        {children}
      </div> : children
  )
}
