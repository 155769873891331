import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'

import ValidationOrder from './ValidationOrder'

export default function ValidateOnMobile(){
  const params = useParams()

  return(
    <>
      <div className="container-validate-order">
        <ValidationOrder mobile={true} orderId={params.id} />
      </div>
    </>
  )
}
