import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Swipeable } from 'react-swipeable'
import {useTransition, animated} from 'react-spring';
import { Switch, NavLink, Route, useHistory } from "react-router-dom";
import useDimension from "../../customHooks/useDimension"
import usePrevious from "../../customHooks/usePrevious"

const SliderPages = ({components, routes}, ref) => {
  const history = useHistory()
  const dimension = useDimension()
  const [ index, setIndex ] = useState()
  const prevIndex = usePrevious(index)
  const [ left, setLeft ] = useState(0)
  const arrRoute = routes

  const transitions = useTransition(history.location, location => location.pathname, {
    from: (d) => {
      let index = arrRoute.indexOf(d.pathname)
      let x = prevIndex > index ? "-10%" : "10%"
      return { opacity: 0, transform : 'translate3d('+x+',0,0)' }
    },
    enter: { opacity: 1, transform : 'translate3d(0,0,0)' },
    leave: (d) => {
      let prevIndex = arrRoute.indexOf(d.pathname)
      // index = to
      let x = prevIndex < index ? "-10%" : "10%"
      return { opacity: 0, transform : 'translate3d('+x+',0,0)' }
    },
  })

  useImperativeHandle(ref,()=>({
    next : () => navLeft(),
    goTo : (url) => {
      let index = arrRoute.indexOf(url)
      history.push(url)
      setIndex(index)
    }
  }))

  useEffect(()=>{
    if(index === undefined || dimension.width > 767){
      if(left !== 0) setLeft(0)
      return;
    }else{
      let links = document.querySelectorAll(".page-navigation>a")

      if(links[index]){
        let t = links[index].offsetLeft+(links[index].offsetWidth/2)
        t = t - (window.innerWidth/2)
        setLeft(-t)
      }

    }
  },[index,dimension.width])

  useEffect(()=>{
    let index = arrRoute.indexOf(history.location.pathname)
    if(index === -1) index = 0;
    setIndex(index)
  },[history.location.pathname])

  function navLeft(){
    if(index === arrRoute.length-1) return;
    let newIndex = index || arrRoute.indexOf(history.location.pathname);

    newIndex++;
    history.push(arrRoute[newIndex])
    setIndex(newIndex)
  }

  function navRight(){
    if(index === 0) return;
    let newIndex = index;
    newIndex--;
    history.push(arrRoute[newIndex])
    setIndex(newIndex)
  }

  const wrapIntoRoute = (WrappedComponent) => (props) => {
    return (
      <Route key={props.key} exact path={props.route}>
        {React.cloneElement(WrappedComponent, { index : props.i, ...WrappedComponent.props })}
      </Route>
    )
  }

  return(
    <div className="slider-pages">
      <Swipeable
        onSwipedLeft={(eventData) => navLeft(eventData)}
        onSwipedRight={(eventData) => navRight(eventData)}
      >
        {
          transitions.map(({ item, props, key }) => (
            <animated.div key={key} className="container-pages" style={{...props}}>
              <Switch location={item}>
                { components.map((component,i)=>(
                  <Route key={`route-${i}`} exact path={routes[i]}>
                    {wrapIntoRoute(component)({ key : `route-${i}`, route : routes[i], i})}
                  </Route>
                ))}
              </Switch>
            </animated.div>
          ))
        }
        { (components[index] && !components[index].props.hideNavigation) &&
          <div className="page-navigation" style={{/*transform : "translateX("+left+"px)"*/}}>
            { components.filter(component => !component.props.noDot).map((component,i)=>(
              <React.Fragment key={`link-${i}`}>
                { component.props.disabled ?
                  <a className="checked disabled"></a> :
                  <NavLink className={component.props.checked ? "checked" : ''} exact to={routes[i]}></NavLink>
                }
              </React.Fragment>
            ))}
          </div>
        }

      </Swipeable>
    </div>
  )
}

export default forwardRef(SliderPages)
