import React from 'react';

import Block from './Block'
import SupportContact from '../../partials/SupportContact'
import Flash from '../../partials/Flash'

import ImgCamTarte from '../../../assets/images/camtarte-1.png'
import ImgJoconde from '../../../assets/images/joconde.png'
import ImgCafet from '../../../assets/images/cafet.png'

export default function Restauration(props) {
  return (
    <>
      <div className="restaurations content large">
        <div className="fullwidth">
          <p>
            <b>SE RESTAURER PENDANT LE SALON</b>
          </p>
        </div>
        <div className="col">
          <Block
            title="La Petite Faim"
            image={ImgCafet}
          >
            <p>(intérieur Hall XXL)</p>
            <p>Un espace ouvert tout au long de la journée pour déjeuner sur le pouce, prendre un café, une pause gourmande.</p>
            <p>Le lieu d’échange et de convivialité.</p>
            <div className="new">
              <label>
                <b>Espace centré et ouvert sur le salon</b>
              </label>
              <p>
                Restauration – pause gourmande<br />
                Espaces de détente<br />
                Échanges –co-working (prises de recharge à dispo)
              </p>

            </div>
          </Block>
        </div>
        <div className="foodTrucks hide-on-tablet">
          <div className="header-trucks">
            <h2>Les Food Trucks</h2>
            <p>(extérieur Hall XXL)</p>
          </div>
          <div className='visual-truck'>
            <img src={ImgJoconde} alt="Le Joconde" />
          </div>
          <div className='visual-truck'>
            <Flash />
            <img src={ImgCamTarte} alt="Cam’Tarte" />
          </div>
          <p className='bold'>Des places assises à votre disposition, couvertes ou en plein air&nbsp;!</p>
          <div className='foodTruck'>
            <h3>Le Joconde</h3>
            <p>Burger et frites maison pour les plus grands appétits !</p>
          </div>
          <div className='foodTruck'>
            <h3>
              <span className='hightlight'>Nouveautés&nbsp;: </span>
              Cam’Tarte
            </h3>
            <p>Des tartes salées et sucrées pour toutes vos envies</p>
          </div>
        </div>
        <div className="foodTrucks-mobile only-on-tablet">
          <div className="header-trucks">
            <h2>Les Food Trucks</h2>
            <p>(extérieur Hall XXL)</p>
            <p className='sub'>Des places assises à votre disposition, couvertes ou en plein air&nbsp;!</p>
          </div>
          <Block
            title="Le Joconde"
            image={ImgJoconde}
          >
            <p>Burger et frites maison pour les plus grands appétits !</p>
          </Block>
          <Block
            title="Cam’Tarte"
            isNew={true}
            image={ImgCamTarte}
          >
            <p>Des tartes salées et sucrées pour toutes vos envies</p>
          </Block>
        </div>
      </div>
      <div className="support content large">
        <SupportContact breakingPoint={900} />
      </div>
    </>
  )
}
