import React, { useEffect, useState } from 'react'
import _ from "lodash";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useGlobalContext } from '../../../GlobalContext'
import usePrevious from '../../../../customHooks/usePrevious'
import useDispatch from '../../../../customHooks/useDispatch'
import useDimension from '../../../../customHooks/useDimension'

import { updateCompanyUser, addCompanyUser, resetCompanyError } from '../../../../actions/company'
import InputField from '../../../partials/form_fields/InputField'
import SelectItems from '../../../partials/form_fields/SelectItems'
import Switch from "react-switch";

import styles from './contributors.module.scss';

export default function FormContributors({ user, isLoading, cancel }) {
  const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i;
  const [formData, setFormData] = useState({});
  const [selectedPresenceDays, setSelectedPresenceDays] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isEmailUsedByContributor, setIsEmailUsedByContributor] = useState(false);
  const [presenceDays, setPresenceDays] = useState();
  const [isLunchChecked, setIsLunchChecked] = useState(false);


  const [context, dispatch] = useGlobalContext();
  const companyReducer = context.companyReducer

  const wasLoading = usePrevious(isLoading)
  const dimension = useDimension()

  const [isEmailError, setIsEmailError] = useState(false)

  //ACTIONS
  const _updateCompanyUser = (data, id, actionLoad) => updateCompanyUser(dispatch, data, id, actionLoad)
  const _addCompanyUser = (data) => addCompanyUser(dispatch, data)
  const _resetCompanyError = useDispatch(resetCompanyError, dispatch)

  // HANDLE ERROR ON EMAIL
  useEffect(() => {
    setIsEmailError(/email/.test(companyReducer.error))
  }, [companyReducer.error])

  useEffect(() => {
    let days = companyReducer.companyList.days.filter(d => d.value !== "cocktail")
    const getDateStr = (date) => {
      const day = format(new Date(date), 'd MMMM', { locale: fr });
      return day;
    }
    days = days.map((day) => ({ value: day.value, label: getDateStr(day.value) } ));
    setPresenceDays([
      ...days,
    ])
  }, []);

  // RESET COMPANY ERROR WHEN UNMOUNT
  useEffect(() => {
    return () => {
      _resetCompanyError()
    }
    // eslint-disable-next-line
  }, [])

  // IF NO USER AND ONLY ON PRESENCE DAYS
  // SELECT IT BY DEFAULT
  useEffect(() => {
    if (!user && presenceDays?.length === 1) {
      setFormData({
        presence: [presenceDays[0].value],
      })
      setSelectedPresenceDays([presenceDays[0].value]);
    }
  }, [presenceDays, user]);

  // INIT PRESENCE DAYS
  // IF THE ARE AN formData
  useEffect(() => {
    if (!user) return;

    let obj = {
      _id: user._id,
      email: user.email,
      profile: {
        lastName: user.profile.lastName,
        firstName: user.profile.firstName,
        function: user.profile.function,
        phone: user.profile.phone
      },
      presence: user.presence
    }
    if (user.presence) {
      obj.presence = user.presence
      setSelectedPresenceDays(user.presence)
    }

    setIsLunchChecked(user.lunch);
    setFormData(obj)
    // eslint-disable-next-line
  }, [])

  // CLOSE FORM CONTACTS AFTER SUBMIT
  useEffect(() => {
    if (wasLoading && !isLoading) {
      cancel()
    }
  }, [isLoading])

  // HANDLE IS READY TO SUBMIT
  useEffect(() => {
    if (formData &&
      (formData.profile &&
        (formData.profile.lastName && formData.profile.lastName !== "") &&
        (formData.profile.firstName && formData.profile.firstName !== "") &&
        (formData.profile.function && formData.profile.function !== "") &&
        (formData.presence && formData.presence.length > 0)
      ) && (
        formData.email &&
        emailReg.test(formData.email)
      ) &&
      !isEmailUsedByContributor
    ) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }

  }, [formData])

  function handleChange(val, error, field) {

    let updatedData = { ...formData }

    if (field === "email" && !error) {
      let found = companyReducer.companyUsers.find((user) => {
        return user.email === val && user.presence.length === 0
      })

      // USER IS A PROPS PASS TO THIS COMPOMENT TO
      // EDIT A USER
      let foundContributors;
      if (!user) {
        foundContributors = companyReducer.companyUsers.find((user) => {
          return user.email === val && user.presence.length > 0
        })
        setIsEmailUsedByContributor(!!foundContributors)
      }

      if (found && !foundContributors) {
        _.set(updatedData, "_id", found._id)
        _.set(updatedData, "profile.lastName", found.profile.lastName)
        _.set(updatedData, "profile.firstName", found.profile.firstName)
        _.set(updatedData, "profile.function", found.profile.function)

        // setSelectedPresenceDays("Ne sera pas présent")

      } else {
        _.unset(updatedData, "_id")
      }
    } else if (field === "email") {
      setIsEmailUsedByContributor(false)
    }
    if (field === "presence" && val) {
      let found = presenceDays.filter(day => val.includes(day.label));
      let updatedDays = found.map((d) => d.value);
      setSelectedPresenceDays(updatedDays);
      _.set(updatedData, field, updatedDays);
    } else {
      _.set(updatedData, field, val);
    }

    setFormData(updatedData)
  }

  function submit() {
    if (!isValid || companyReducer.isLoading || isEmailUsedByContributor) return;
    if (formData._id) {
      submitUdpateformData()
    } else {
      submitCreateformData()
    }
  }

  function submitUdpateformData() {

    var data = _.cloneDeep(formData)

    if (!data.presence) {
      data.presence = []
    }

    data.presence = data.presence.filter(d => !!d);
    _updateCompanyUser(data, formData._id, !user ? "update-contributors" : null)

  }

  function submitCreateformData() {
    let data = _.cloneDeep(formData)
    data.company = companyReducer.company._id

    if (!data.presence) {
      data.presence = []
    }

    data.presence = data.presence.filter(d => !!d)
    _addCompanyUser(data)

  }

  function toggleLunch(val) {
    let updatedData = { ...formData }
    _.set(updatedData, "lunch", val)
    setFormData(updatedData)
    setIsLunchChecked(val)
  }

  function getSelectedItems() {
    let found = presenceDays.filter(day => selectedPresenceDays.includes(day.value));
    return (found && found.length > 0) ? found.map(d => d.label) : []
  }

  return (
    <MobileWrapper isMobile={dimension.width < 767}>
      <section className={dimension.width > 767 ? styles['module-contact'] : `${styles['module-contact']} ${styles.mobile}`}>

        {dimension.width < 767 ?
          <h2>
            Inscription d’un collaborateur
          </h2> : null
        }
        <div className={styles['grid-form']}>
          <div className={styles['container-email']}>
            <InputField
              id={"email"}
              value={formData.email ? formData.email : ""}
              title={"Email"}
              placeholder={""}
              type={"string"}
              format={"email"}
              required={true}
              disabled={false}
              handleChange={(val, error) => handleChange(val, error, "email")}
            />
            {isEmailError && companyReducer.error ?
              <p className="warn">{companyReducer.error}</p> : null
            }
            {isEmailUsedByContributor &&
              <p className="warn">Un collaborateur utilise déjà cette email</p>
            }
          </div>
          <InputField
            id={"lastName"}
            value={formData.profile ? formData.profile.lastName : ""}
            title={"Nom"}
            placeholder={""}
            type={"string"}
            textTransform={"uppercase"}
            required={true}
            disabled={
              !emailReg.test(formData.email) ||
              isEmailUsedByContributor
            }
            handleChange={(val, error) => handleChange(val, error, "profile.lastName")}
          />
          <InputField
            id={"firstName"}
            value={formData.profile ? formData.profile.firstName : ""}
            title={"Prénom"}
            placeholder={""}
            type={"string"}
            textTransform={"capitalized"}
            required={true}
            disabled={
              !emailReg.test(formData.email) ||
              isEmailUsedByContributor
            }
            handleChange={(val, error) => handleChange(val, error, "profile.firstName")}
          />
          <InputField
            id={"function"}
            value={formData.profile ? formData.profile.function : ""}
            title={"Fonction"}
            placeholder={""}
            type={"string"}
            textTransform={"uppercase"}
            required={true}
            disabled={
              !emailReg.test(formData.email) ||
              isEmailUsedByContributor
            }
            maxLength={"30"}
            handleChange={(val, error) => handleChange(val, error, "profile.function")}
          />
          <div className={styles['container-phone']}>
            <InputField
              value={formData.profile ? formData.profile.phone : ""}
              title={"Téléphone"}
              placeholder={""}
              type={"string"}
              maskNumber={"## ## ## ## ##"}
              format={"phone"}
              handleChange={(val, error) => handleChange(val, error, "profile.phone")}
            />
          </div>
        </div>
        <div className={`${styles['container-input']} ${styles['container-select-items']} container-input`}>
          <label>Présence sur le salon <span className={`${styles.required} required`}>*</span></label>
          {(presenceDays && presenceDays.length > 0) &&
            <SelectItems
              items={presenceDays.map(day => day.label)}
              selectedItems={getSelectedItems()}
              required={true}
              uniq={false}
              handleChange={(val, error) => handleChange(val, error, "presence")}
            />
          }
        </div>
        <div className="container-switch">
          <p>
            Présence au déjeuner
            <span className="required">*</span>
          </p>
          <Switch
            onColor='#080'
            offColor='#FF0009'
            onChange={toggleLunch}
            checked={isLunchChecked}
          />
        </div>
        <div className={styles['container-button']}>
          <button className={`${styles.secondary} secondary`} onClick={cancel}>Annuler</button>
          <button className={!isValid ? "disabled" : ""} onClick={submit}>
            {formData && formData._id ?
              "Modifier" : "Ajouter"
            }
            {companyReducer.isLoading ?
              <div className={`${styles.loader} loader`}></div> : null
            }
          </button>
        </div>
        {!isEmailError && companyReducer.error ?
          <p className="warn">{companyReducer.error}</p> : null
        }
      </section>
    </MobileWrapper>
  )
}

const MobileWrapper = ({ isMobile, wrapper, children }) => {
  return (
    isMobile ?
      <div className="container-mobile">
        <div className="overlay-module-contact"></div>
        {children}
      </div> : children
  )
}
