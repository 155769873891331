import React, { useState, useEffect } from 'react';
import useDimension from "../../../customHooks/useDimension"
import Flash from '../../partials/Flash';

export default function Block(props) {
  const dimension = useDimension()
  const [isOpen, setIsOpen] = useState(props.isOpen ? props.isOpen : null)
  const [isMobile, setIsMobile] = useState(dimension.width < 768)

  useEffect(() => {

    if (!isMobile && dimension.width < 768) {
      setIsMobile(true)
    } else if (isMobile && dimension.width >= 768) {
      setIsMobile(false)
    }

  }, [dimension.width])

  function renderClass() {
    let open = isOpen ? "open" : ""
    return isMobile ? "title-mobile " + open : ""
  }
  ;

  return (
    <div className="block">
      {props.isNew && <Flash />}
      {props.image && (!isMobile || isOpen) ?
        <img src={props.image} alt={props.title} /> : null
      }
      <h3 className={renderClass()}>
        <span>
          {props.isNew && <span className='hightlight'>Nouveautés&nbsp;: </span>}
          <span dangerouslySetInnerHTML={{ __html: props.title }} ></span>
        </span>
        {isMobile ?
          <a onClick={() => setIsOpen(!isOpen)} className={isOpen ? "show active" : "show"}></a> : null
        }
      </h3>
      {!isMobile || isOpen ?
        props.children : null
      }

    </div>
  )
}
