import React, { useState, useRef, useImperativeHandle } from 'react'

const forwardModuleUploadDocs = React.forwardRef(ModuleUploadDocs)
export default forwardModuleUploadDocs

function ModuleUploadDocs(props,ref){
  const inputFiles = useRef()

  const [ fileName , setFileName ] = useState()

  useImperativeHandle(ref,()=>({
    setFileName : (fileName) => setFileName(fileName)
  }))

  function upload(){
    inputFiles.current.click();
  }

  async function handleChange(e){
    let file = e.target.files[0]

    if(props.handleChange){
      props.handleChange(props.isMultiple ? e.target.files : e.target.files[0])
    }

    let fileName;
    if(props.isMultiple){
      let arr = Array.from(e.target.files)
      let names = arr.map((f)=>{
        return f.name
      })

      fileName = names.join(" / ")
    }else{
      fileName = file.name
    }

    setFileName(fileName)

  }

  return (
    <div className="upload-doc">
      { fileName ? <p className="filename">{fileName}</p> : null }
      <input accept="image/png, image/jpeg, application/pdf" className="hide" onChange={(e) => handleChange(e)} ref={inputFiles} type="file" multiple={props.isMultiple ? props.isMultiple : false}/>
      <button onClick={upload}>Joindre des fichiers</button>
      {props.children}
    </div>
  )

}
