import React from "react"
import useDimension from '../../../../customHooks/useDimension'
import { Tooltip } from '@material-ui/core';
import InfoBox from '../../../../assets/images/icons/icon-box-open-solid.svg'

export default function TooltipInfo({text}){
  const dimension = useDimension()

  return (
    <Tooltip enterTouchDelay={50} placement={dimension.width > 768 ? "right-end" : "top"}
      title={
        <React.Fragment>
          <div className="content-tooltip">
            <p>{text}</p>
          </div>
        </React.Fragment>
      }>
      <span className="tooltip-box"><img src={InfoBox}/></span>
    </Tooltip>

  )
}
