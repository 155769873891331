import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import {useTransition, animated} from 'react-spring';

// import Products from './Products'
import Products from './Products'
import Product from './Product'

const ProductsSwitch = (props) => {
  const location = useLocation()

  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0, transform : 'translate3d(-40px,0,0)' },
    enter: { opacity: 1, transform : 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform : 'translate3d(40px,0,0)' },
  })

  return (
    <>
    <div className="products-page">
      {
        transitions.map(({ item, props, key }) => {
          return (
            <animated.div className="" key={key} style={props}>
              <Switch location={item}>
                <Route exact path="/products" component={Products}/>
                <Route exact path="/products/:id" component={Product}/>
              </Switch>
            </animated.div>
          )
        })
      }
    </div>
    </>
  )


}

export default ProductsSwitch;
