import {
  UPDATE_USERS,
  USERS_LOADING,
  USER_RESET_SUBMITTED,
  USERS_RESET_ERROR,
  ERROR_USERS
} from "../actions/types"

const initialState = { error : null, submitted : false, isLoading : false};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USERS :
      return {...state, submitted : true, isLoading : false};
    case USER_RESET_SUBMITTED :
      return {...state, submitted : false};
    case USERS_LOADING :
      return {...state, isLoading : true};
    case USERS_RESET_ERROR :
      return {...state, error : null, isLoading : false};
    case ERROR_USERS :
      return {...state, error : action.payload.error};
    default:
      return {...state};
  }
}
