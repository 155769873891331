import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import _ from 'lodash';
import { useHistory } from "react-router"

import { updateFeedback } from '../../../actions/users'
import { useGlobalContext } from '../../GlobalContext'
import useDimension from "../../../customHooks/useDimension"
import usePrevious from "../../../customHooks/usePrevious"
import useEventTakingPlace from '../../../customHooks/useEventTakingPlace'

import SliderPages from "../../partials/SliderPages"
import Question from './Question'
import QuestionRestauration from './QuestionRestauration'
import QuestionInscriptions from './QuestionInscriptions'

import Form from './Form'
import Suggestion from './Suggestion'

export default function Avis(){
  const wasMount = useRef()
  const [ context, dispatch] = useGlobalContext()
  const [ answers, setAnswers ] = useState({})
  const [ formData, setFormData ] = useState({})
  const sliderPages = useRef({});
  const [ minimalHeigth, setMinimalHeigth ] = useState(0)
  const dimension = useDimension()
  const isEventTakingPlace = useEventTakingPlace()
  const authReducer = context.authReducer

  const [ timestampAnswer, setTimestampAnswer ] = useState()

  // ACTIONS
  const _updateFeedback = (data) => updateFeedback(dispatch,data)

  // INIT FEEDBACK
  useEffect(()=>{
    setFormData({
      name : (authReducer.user.feedback && authReducer.user.feedback.name) ? authReducer.user.feedback.name : "",
      email : (authReducer.user.feedback && authReducer.user.feedback.email) ? authReducer.user.feedback.email : "",
      phone : (authReducer.user.feedback && authReducer.user.feedback.phone) ? authReducer.user.feedback.phone : "",
      suggestion : (authReducer.user.feedback && authReducer.user.feedback.suggestion) ? authReducer.user.feedback.suggestion : ""
    })
    setAnswers({ answers : (authReducer.user.feedback && authReducer.user.feedback.answers) ? authReducer.user.feedback.answers : {}})
  },[])

  useEffect(()=>{
    if(Object.values(answers).length === 0) return;
    if(!wasMount.current){
      wasMount.current = true;
      return
    }
    sliderPages.current.next();
  },[answers])

  useEffect(()=>{
    setMinimalHeigth(document.querySelector('.avis').offsetHeight-60)
  },[])

  useEffect(()=>{
    if(!timestampAnswer) return;
    submit()
  },[timestampAnswer])

  const arrRoute = [
    "/avis",
    "/avis/question-2",
    "/avis/question-3",
    "/avis/question-4",
    "/avis/question-5",
    "/avis/question-6",
    "/avis/question-7",
    "/avis/question-8",
    "/avis/question-9",
    "/avis/suggestion",
    "/avis/form",
    "/avis/merci"
  ]

  function handleAnswer(field,val){
    let updatedData = {...answers}
    _.set(updatedData, field, val)
    setTimestampAnswer(Date.now())
    setAnswers(updatedData)
  }

  function handleForm(field,val){
    let updatedData = {...formData}
    _.set(updatedData, field, val)
    setFormData(updatedData)
  }

  function submit(action){
    _updateFeedback({
      ...formData,
      ...answers
    }).then((res)=>{
      if(action === "click"){
        sliderPages.current.goTo('/avis/merci')
      }
    })
  }

  return(
    <div className="avis">
      { isEventTakingPlace ?
        <SliderPages
          ref={sliderPages}
          components={[
            <QuestionInscriptions height={minimalHeigth} handleAnswer={(val) => handleAnswer('answers.inscription',val)} />,
            <Question height={minimalHeigth} handleAnswer={(val) => handleAnswer('answers.accueil',val)} question={"L’accueil au salon ?"}/>,
            <Question height={minimalHeigth} handleAnswer={(val) => handleAnswer('answers.offres',val)} question={"Les offres salon ?"}/>,
            <Question height={minimalHeigth} handleAnswer={(val) => handleAnswer('answers.animations',val)} question={"Les conférences et animations ?"} />,
            <Question height={minimalHeigth} handleAnswer={(val) => handleAnswer('answers.application',val)} question={"L’application ?"} />,
            <Question height={minimalHeigth} handleAnswer={(val) => handleAnswer('answers.organisation',val)} question={"L'organisation générale du salon ?"} />,
            <Question height={minimalHeigth} handleAnswer={(val) => handleAnswer('answers.restauration',val)} question={"La restauration ?"} />,
            <QuestionRestauration height={minimalHeigth} handleAnswer={(val) => handleAnswer('answers.restaurant',val)} />,
            <Suggestion
              height={minimalHeigth}
              handleForm={(val) => handleForm('suggestion',val)}
              suggestion={formData.suggestion}
              sliderPages={sliderPages}
              submit={submit}
            />,
            <Form
              height={minimalHeigth}
              data={formData}
              handleForm={(field,val) => handleForm(field,val)}
              submit={() => submit("click")}
            />,
            <LastStep height={minimalHeigth} sliderPages={sliderPages}/>,

          ]}
          routes={arrRoute}
        /> :
        <div>
          <AvisInactif height={minimalHeigth}/>
        </div>
      }

    </div>
  )
}

const AvisInactif = ({height}) => {
  return (
    <div className="content avis-inactif" style={{height : height+"px"}}>
      <h1>
        Votre avis
      </h1>
      <div className="centered">
        <p>
          Pendant le salon, donnez votre avis sur le salon !
        </p>
      </div>
    </div>
  )
}

const LastStep = ({sliderPages, height}) => {
  const history = useHistory()

  return (
    <div className="content last-step" style={{height : height+"px"}}>
      <h1>
        Merci pour vos retour !
      </h1>
      <div className="centered">
        <button className="secondary" onClick={() => history.push("/salon")}>
          Retour à l'accueil
        </button>
      </div>
    </div>
  )
}
