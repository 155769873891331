import { useState, useEffect } from 'react';
import { dateStart, dateEnd ,dateEndQuizz } from '../constant/index'
import moment from 'moment';
export default function useEventTakingPlace(event){
  const [isActive, setIsActive] = useState(false)

  useEffect(()=>{
    // Sept index = 8, get month start at 0
    let dStart = dateStart;
    let dEnd;

    if(event === "quizz"){
      dEnd = dateEndQuizz
    }else{
      dEnd = dateEnd
    }

    let isBetween = moment().isBetween(dStart,dEnd)
    if(isBetween){
      setIsActive(true)
    }else{
      setIsActive(false)
    }
  },[])

  return isActive
}
