import React, { useState, useEffect } from 'react';

const Suggestion = ({sliderPages, handleForm, submit, suggestion, height}) => {

  function next(){
    sliderPages.current.next()
    submit()
  }

  return (
    <div className="content suggestion" style={{height : height+"px"}}>
      <p>
        Vos questions, remarques et suggestion
      </p>
      <div className="centered">
        <textarea
          rows="5"
          value={suggestion}
          onChange={(e) => handleForm(e.target.value)}
        />
        { sliderPages.current &&
          <button onClick={next}>
            Valider
          </button>
        }
      </div>
    </div>
  )
}

export default Suggestion
