import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from "react-router-dom";

import Checkmark from '../../../../assets/images/checkmark-circle-outline.svg'

import StepContext from './StepContext'

export default function HeaderStandForm (props) {
  const stepContext = useContext(StepContext);

  const [ active, setActive ] = useState();

  useEffect(() => {
    setActive(props.match.params.step);
  }, [props.match.params.step]);

  function renderNavLink(){
    return (
      <>
        <li className={active === "step-1" ? "nav-step active" : "nav-step"} >
          <NavLink to="/stand/step-1" >1</NavLink>
          {stepContext.step1 && (
            <div className="checkmark"><img src={Checkmark} alt="step 1 checked"/></div>
          )}
        </li>
        <li className={active === "recapitulatif" ? "nav-step active" : "nav-step"} >
          <NavLink to="/stand/recapitulatif" >2</NavLink>
        </li>
      </>
    )
  }


  return (
    <div className={"nav-provider-from"}>
      <div className="title-page">
        <h2>Mon stand</h2>
      </div>
      <ul >
        {renderNavLink()}
      </ul>
    </div>
  )

}
