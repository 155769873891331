import React from 'react';

import Block from './Block'
import SupportContact from '../../partials/SupportContact'

export default function Hebergements (props){
  return(
    <>
      <div className="content large">
        <div className="fullwidth">
          <p>Les hôtels ci-dessous, proches d'Exponantes vous proposent des avantages tarifaires sur<br/> présentation du code&nbsp;: <b>{process.env.REACT_APP_NAME} 2022</b></p>
        </div>
        <div className="col">
          <Block
            title = "Sure Hotel by Best Western Nantes Beaujoire"
          >
            <p>À 2,6 km du parc des expositions de la Beaujoire.<br/>
            27 rue du Chemin Rouge, 44300 Nantes</p>
            <p><b>94€</b> B&B au lieu de 117€ (-20%)</p>
            <p className="tel">Téléphone: <a href="tel:02 14 00 07 89">02 14 00 07 89</a></p>
          </Block>
          <Block
            title = "L'hôtel Zenitude la Beaujoire"
          >
            <p>À 2,6 km du parc des expositions de la Beaujoire.<br/>
            2 rue des Citrines 44300 Nantes</p>
            <p><b>86€</b> au lieu de 77.40€ (-10%)</p>
            <p className="tel">Téléphone: <a href="tel:02 28 08 80 00">02 28 08 80 00</a></p>
            <p className="web"><a href='https://www.zenitude-hotel-residences.com/fr_FR/residence/nantes/138'>Site internet</a></p>
          </Block>
        </div>
        <div className="col">
          <Block
            title = "Ibis Beaujoire"
          >
            <p>À 1,7 km du parc des expositions de la Beaujoire.<br/>
            5 rue du Moulin de la halvèque, 44300 Nantes</p>
            <p>
              <b>113€</b> B&B au lieu de 129.90€ (-13%)
            </p>
            <p className="tel">Téléphone: <a href="tel:02 40 93 22 22">02 40 93 22 22</a></p>
          </Block>
        </div>
        <div className="col">
          <Block
            title = "Le Beaujoire Hôtel"
          >
            <p>À 1 km du parc des expositions de la Beaujoire.<br/>
            15 rue des Pays de la Loire, 44300 Nantes</p>
            <p>
              <b>92€</b> B&B au lieu de 103,50 (-11%)
            </p>
            <p>
              Restaurant ouvert de 12h à 14h avec 2 formules: Entrée/Plat ou Plat/Dessert à 15€
              et Entrée/Plat/Dessert à 18€<br />
              Pour le restaurant, les personnes intéressées peuvent contacter pour réserver au <span className="tel"><a href="tel:02 40 93 00 01">02 40 93 00 01</a> </span >
              ou par email à <span className="tel"><a href="mailto:contact@beaujoire-hotel.com">contact@beaujoire-hotel.com</a></span>
            </p>
            <p className="tel">Téléphone: <a href="tel:02 40 93 00 01">02 40 93 00 01</a></p>
          </Block>
        </div>
      </div>
      <div className="support content large">
        <SupportContact breakingPoint={900}/>
      </div>
    </>
  )
}
