import axios from 'axios';
import {
  ERROR_AUTH,
} from './types';

export const API_URL = process.env.REACT_APP_API_URL;

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  console.log(error);

  // type === ERROR_AUTH && 
  if (error.response !== undefined && error.response.status === 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      localStorage.removeItem('company');
      localStorage.removeItem('exhibition');
    }
  }

  if (error.response?.status === 401) {
    window.location.assign('/#/');
  }

  dispatch({
    type,
    payload: error.response?.data,
  });
}

export function getData(errorType, url, dispatch, isAuthReq) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const token = localStorage.getItem('token');
    const exhibition = localStorage.getItem('exhibition');
    const config = { headers: {} };

    if (isAuthReq) {
      config.headers.Authorization = token;
    }

    if (exhibition) config.headers.exhibition = exhibition;

    axios.get(requestUrl, config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function deleteData(errorType, url, dispatch) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const token = localStorage.getItem('token');
    const exhibition = localStorage.getItem('exhibition');

    const config = {
      headers: {
        Authorization: token,
      },
    };

    if (exhibition) config.headers.exhibition = exhibition;

    axios.delete(requestUrl, config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function postData(errorType, isAuthReq, url, dispatch, data) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const exhibition = localStorage.getItem('exhibition');

    const config = {
      timeout: 1000 * 60,
      headers: {},
    };

    if (isAuthReq) {
      config.headers.Authorization = localStorage.getItem('token');
    }

    if (exhibition) config.headers.exhibition = exhibition;

    axios.post(requestUrl, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        errorHandler(dispatch, error, errorType);
        reject();
      });
  });
}

export function putData(errorType, isAuthReq, url, dispatch, data) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;
    const exhibition = localStorage.getItem('exhibition');

    const config = {
      timeout: 1000 * 60,
      headers: {},
    };

    if (isAuthReq) {
      config.headers.Authorization = localStorage.getItem('token');
    }

    if (exhibition) config.headers.exhibition = exhibition;

    axios.put(requestUrl, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}
