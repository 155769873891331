import React, { useEffect, useState, useRef } from 'react';
import _ from "lodash";
import { NavLink } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';

import { useGlobalContext } from '../../../../GlobalContext'
import { setFormCompany, updateCompany } from '../../../../../actions/company'
import { updateCreateMutipleUsers } from '../../../../../actions/users'

import SupportContact from '../../../../partials/SupportContact'
import TooltipInfo from '../../../../partials/TooltipInfo'
import InputField from '../../../../partials/form_fields/InputField'
import SelectItems from '../../../../partials/form_fields/SelectItems'

import Select from 'react-select';
import selectStyle from '../../../../partials/form_fields/selectStyle'

const SkeletonTitle = () => (
  <Skeleton variant="text" width="250px" style={{
     marginTop: 15,
     marginBottom: 15,
     marginLeft: 30,
     marginRight: 30,
   }} />
)

const SkeletonButton = () => (
  <Skeleton variant="rect" width="200px" style={{
     height: "45px",
     borderRadius: "22px",
     marginTop: 15,
     marginBottom: 15 }}
  />
)

export default function Step1(props){
  const [ context, dispatch ] = useGlobalContext();
  const {
    authReducer,
    companyReducer,
    usersReducer
  } = context;

  const [wasSubmitted , setWasSubmitted] = useState(false);

  const [user, setUser] = useState();
  const [insurance, setInsurance] = useState();

  const [products, setProducts] = useState();
  const [selectedProducts, setSelectedProducts] = useState();

  const [selectedMainUser, setSelectedMainUser] = useState();
  const [optsMainUser, setOptsMainUser] = useState();

  const [isStepCompleted, setIsStepCompleted] = useState(false);

  const errors = useRef({});

  const [currentFormData, setCurrentFormData] = useState();

  //ACTIONS
  const _setFormCompany = (data) => setFormCompany(dispatch, data);
  const _updateCompany = (data, id) => updateCompany(dispatch, data, id);
  const _updateCreateMutipleUsers = (updatedUsers, newUsers, deletedUsers) => updateCreateMutipleUsers(dispatch, updatedUsers, newUsers, deletedUsers);

  useEffect(()=>{
    if (!companyReducer.formData || selectedProducts) return;
    setSelectedProducts(companyReducer.formData.products)
  },[companyReducer.formData]);

  // OPTS MAIN USER
  useEffect(()=>{
    if (!companyReducer.companyUsers) return;
    let opts = companyReducer.companyUsers.map((user) => {
      const label = (user?.profile?.lastName && user?.profile?.firstName) ?
      `${user.profile.lastName} ${user.profile.firstName}` : user.email;
      return { label , value : user._id };
    });

    let found = opts.find((opt) => opt.value === companyReducer.company.mainUser);
    setSelectedMainUser(found);

    setOptsMainUser(opts);
  },[companyReducer.companyUsers])

  useEffect(()=>{
    if(!companyReducer.formData) return;

    //MAIN USER
    setUser(companyReducer.formData.mainUser)

    //set form state
    setCurrentFormData(companyReducer.formData)

    //PRODUCTS

    setInsurance(companyReducer.formData.insurance)

  },[companyReducer.formData])

  useEffect(()=>{
    if(companyReducer.submitted){
      setWasSubmitted(true)
    }
  },[companyReducer.submitted])


  // RESET WASSUBMITTED AND companyReducer.submitted,usersReducer.submitted
  useEffect(()=>{
    if(wasSubmitted){
      let timer = setTimeout(()=>{
        dispatch({type : "COMPANY_RESET_SUBMITTED"})
        setWasSubmitted(false)
        clearTimeout(timer)
      },3000)
      return ()=>{
        dispatch({type : "COMPANY_RESET_SUBMITTED"})
        clearTimeout(timer)
      }
    }
  },[wasSubmitted])

  // IF THERE ARE A RESET MESSAGE ERROR ON UNMOUNT
  useEffect(()=>{
    if(usersReducer.error){
      return () =>{
        dispatch({
          type : "USERS_RESET_ERROR"
        })
      }
    }
  },[usersReducer.error])


  // SET OPTIONS LIST PRODUCTS
  useEffect(()=>{
    if(!companyReducer.companyList) return;
    setProducts(companyReducer.companyList.products)

  },[companyReducer.companyList])

  // MANAGE ERRORS FIELDS
  useEffect(()=>{
    let keysErrors = Object.keys(errors.current)
    let missingFields = []

    keysErrors.forEach((key)=>{
      if(errors.current[key]){
        missingFields.push(key)
      }
    })

    if(missingFields.length === 0){
      setIsStepCompleted(true)
    }else{
      setIsStepCompleted(false)
    }

  },[errors.current])

  function handleChange (val,error,field){

    if(field === "products"){
      setSelectedProducts(val)
    }

    // MANAGE ERRORS
    let cloneErrors = _.cloneDeep(errors.current)
    let key = field.split(".")[field.split(".").length-1]
    _.set(cloneErrors, key, error)
    errors.current = cloneErrors
    //

    let updatedData = _.cloneDeep(currentFormData)
    _.set(updatedData, field, val)

    setCurrentFormData(updatedData)
    _setFormCompany(updatedData)

  }

  function handleSelectMainUser(val){
    let found = companyReducer.companyUsers.find(user => user._id === val.value )

    let updatedData = _.cloneDeep(currentFormData)
    _.set(updatedData, "mainUser.email", found.email)
    _.set(updatedData, "mainUser.profile.function", found.profile.function)
    setCurrentFormData(updatedData)
    _setFormCompany(updatedData)

    setSelectedMainUser(val)

  }

  function submit(){

    /// UPDATE COMPANY
    let companyData = companyReducer.formData
    companyData = {
    	"products":companyData.products,
      "mainUser" : selectedMainUser.value,
    	"insurance":{
    		"company": companyData.insurance.company ? companyData.insurance.company : "",
    		"number": companyData.insurance.number ? companyData.insurance.number : ""
    	}
    }

    _updateCompany(companyData,companyReducer.formData.id)

    // // CREATE && UPDATE USER
    // let dataMainUser = companyReducer.formData.mainUser
    // let userData = {
    //   _id : companyReducer.formData.mainUser._id,
    //   "profile": {
    //       "lastName": dataMainUser.profile.lastName,
    //       "firstName": dataMainUser.profile.firstName,
    //       "function": dataMainUser.profile.function
    //   }
    // }
    //
    // let updatedUsers = [userData]
    // let newUsers = []
    //
    // if(companyReducer.formData.contacts &&
    //   companyReducer.formData.contacts.length>0
    // ){
    //   companyReducer.formData.contacts.map((user)=>{
    //     if(user._id){
    //       let obj = {
    //         _id : user._id,
    //         profile : {
    //           lastName : user.profile.lastName,
    //           firstName : user.profile.firstName,
    //           function : user.profile.function,
    //           category : user.profile.category
    //         },
    //         email : user.email
    //       }
    //       updatedUsers.push(obj)
    //     }else{
    //       let obj = _.cloneDeep(user)
    //       obj.type = companyReducer.company.type
    //       newUsers.push(obj)
    //     }
    //   })
    // }
    //
    // let contactsBeforeUpdate = companyReducer.companyUsers.filter((user)=>{
    //   return user._id !== authReducer.user._id
    // })
    // let deletedUsers = _.differenceBy(contactsBeforeUpdate,updatedUsers,'_id')
    //
    // _updateCreateMutipleUsers(updatedUsers,newUsers,deletedUsers)

  }

  function getSelectedFunctions(val){
    return { label : val, value : val}
  }

  return(
    <div className="step formulaire">
      { props.isLoading ?
        <SkeletonTitle/> :
        <p className="section-title">Identification</p>
      }
      <section>
        <InputField
          value={companyReducer.formData ? companyReducer.formData.name : ""}
          title={"Raison Sociale"}
          placeholder={""}
          type={"string"}
          required={true}
          disabled={true}
          loading={props.isLoading}
        />
      </section>
      { props.isLoading ?
        <SkeletonTitle/> :
        <p className="section-title">Interlocuteur principal</p>
      }
      {
        <section className="main-user">
          {
            optsMainUser &&
            <div className="container-select">
              <label>
                Nom prénom
              </label>
              <Select
                options={optsMainUser}
                value={selectedMainUser}
                isSearchable={false}
                styles={selectStyle}
                onChange={(val) => handleSelectMainUser(val)}
              />
            </div>
          }
            {/*
              <InputField
                value={user ? user.profile.lastName : ""}
                title={"Nom"}
                placeholder={""}
                type={"string"}
                required={true}
                textTransform={"uppercase"}
                loading={props.isLoading || !user ? true : false}
                disabled={false}
                handleChange={(val,error) => handleChange(val,error,"mainUser.profile.lastName")}
              />
              <InputField
                value={user ? user.profile.firstName : ""}
                title={"Prénom"}
                placeholder={""}
                type={"string"}
                required={true}
                textTransform={"capitalized"}
                loading={props.isLoading}
                disabled={false}
                handleChange={(val,error) => handleChange(val,error,"mainUser.profile.firstName")}
              />
            */}
            <InputField
              value={user ? user.profile.function : ""}
              title={"Fonction"}
              placeholder={""}
              type={"string"}
              required={true}
              textTransform={"uppercase"}
              loading={props.isLoading}
              disabled={true}
              maxLength={"30"}
              handleChange={(val,error) => handleChange(val,error,"mainUser.profile.function")}
            />

            <InputField
              value={user ? user.email : ""}
              title={"Adresse mail"}
              placeholder={""}
              format={"email"}
              type={"email"}
              required={true}
              disabled={true}
              loading={props.isLoading}
              handleChange={(val,error) => handleChange(val,error,"mainUser.email")}
            />
        </section>
      }
      <div className="centered">
        <NavLink to="/contributors"><button>Mes collaborateurs</button></NavLink>
      </div>
      { props.isLoading ?
        <SkeletonTitle/> :
        <p className="section-title">Famille(s) de produits travaillés<span className="required">*</span></p>
      }
      <section>
        <SelectItems
          items={products}
          selectedItems={selectedProducts}
          loading={props.isLoading}
          required={true}
          handleChange={(val,error) => handleChange(val,error,"products")}
        />
      </section>
      { props.isLoading ?
        <SkeletonTitle/> :
        <p className="section-title">
          Compagnie
          <TooltipInfo text={
            `${process.env.REACT_APP_NAME} procède à la collecte de vos données personnelles. Les informations recueillies font l’objet d’un traitement informatique destiné à la gestion des visiteurs sur les salons organisés a ${process.env.REACT_APP_NAME}. Les destinataires des données sont ${process.env.REACT_APP_NAME} et les centres E.Leclerc, employeurs des salariés. 
            Conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, 
            vous bénéficiez d’un droit d’accès et de rectification aux informations, 
            que vous pouvez exercer en vous adressant à "${authReducer?.exhibition?.contact?.email}", qui vous concernent,
             Vous pouvez vous opposer à la collecte de vos données pour des motifs légitimes", Pour rappel, ces données peuvent être conservées 1 an.`
          }/>
        </p>
      }
      <section className="company">
        <InputField
          value={insurance ? insurance.company : ""}
          title={"Nom Compagnie"}
          placeholder={""}
          type={"string"}
          required={false}
          loading={props.isLoading}
          handleChange={(val,error) => handleChange(val,error,"insurance.company")}
        />
        <InputField
          value={insurance ? insurance.number : ""}
          title={"N° de Police d'assurance"}
          placeholder={""}
          type={"string"}
          required={false}
          loading={props.isLoading}
          handleChange={(val,error) => handleChange(val,error,"insurance.number")}

        />
      </section>
      { wasSubmitted &&
        <p className="succeed">Les changements on été enregistrés avec succées !</p>
      }
      { companyReducer.error &&
        <p className="warn">Un problème est survenu, veuillez contacter le support</p>
      }
      <div className="container-support">
        <SupportContact pageForm={true}/>
        {props.isLoading ?
          <SkeletonButton /> :
          <>{ isStepCompleted ?
            <button onClick={submit} >
              Valider
              { usersReducer.isLoading && <span className="loader"></span>}
            </button> :
            <button className={"disabled"}>Suivant</button>
          }</>

        }
      </div>

    </div>
  )

}
