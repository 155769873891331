import moment from 'moment';

export const dateStart = moment("14/09/2022 8:30", "DD/MM/YYYY HH:mm")
export const dateEnd = moment("15/09/2022 17:00", "DD/MM/YYYY HH:mm")
//
export const dateEndQuizz = moment("15/09/2022 15:30", "DD/MM/YYYY HH:mm")

export const PRODUCTS_PER_REQUEST = 10;

// export const dateStart = moment("27/08/2022 8:30", "DD/MM/YYYY HH:mm")
// export const dateEnd = moment("28/08/2022 18:00", "DD/MM/YYYY HH:mm")

// export const dateEndQuizz = moment("28/08/2022 15:30", "DD/MM/YYYY HH:mm")
