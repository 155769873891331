import React, { useState, useEffect } from 'react';
import _ from "lodash";
import { useGlobalContext } from '../../../../GlobalContext'
import { setFormShowroom } from '../../../../../actions/showroom'

import InputField from '../../../../partials/form_fields/InputField'

const Dimensions = ({ isLoading }) => {
  const [ context, dispatch ] = useGlobalContext();
  const { authReducer, showRoomReducer } = context;
  const { exhibition: { showroomType } } = authReducer;

  const [ initialDimension, setInitialDimension ] = useState();

  //ACTIONS
  const _setFormShowroom = (data) => setFormShowroom(dispatch,data)

  /// INIT DIMENSION
  useEffect(() => {
    if(isLoading) return;
    setInitialDimension(showRoomReducer?.formData?.dimension);
  }, [isLoading]);

  function changeDimension(val, field) {
    let updatedData = _.cloneDeep(showRoomReducer.formData);
    updatedData[field] = parseInt(val, 10);
    _setFormShowroom(updatedData);
  }

  return (
    <div className='dimension'>
      <p className="section-title">Location de l'espace d'exposition</p>
      <section>
        <p>
          Dimensions du stand en 
          {`${showroomType === 'linear' ? ' mètre linéaire' : ''}`}
          {`${showroomType === 'square' ? ' m2' : ''}`}
          <br/>
        </p>
        <div className="input-dimension">
          <InputField
            value={initialDimension || ''}
            placeholder={""}
            type={"number"}
            required={false}
            loading={false}
            handleChange={(val) => changeDimension(val, 'dimension')}
          />
        </div>
      </section>
    </div>
  );
};

export default Dimensions;