import {
  GET_COMPANY, // RESPONSE GIVE ASSEMBLIES
  ADD_MONTAGE,
  UPDATE_MONTAGE,
  MONTAGE_LOADING,
  ERROR_MONTAGE
} from "../actions/types"

const initialState = { montage : null, isLoading : false, error : null};

export default function showRoomReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY :
      return { ...state, montage : action.payload.assemblies[0] }
    case ADD_MONTAGE :
      return { ...state , montage : action.payload, isLoading : false }
    case UPDATE_MONTAGE :
      return { ...state , montage : action.payload, isLoading : false }
    case MONTAGE_LOADING :
      return { ...state , isLoading : true }
    case ERROR_MONTAGE :
      return {...state};
    default:
      return {...state};
  }
}
