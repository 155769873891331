import React, { useState } from 'react'
import { orderBy } from 'lodash'
import { useGlobalContext } from '../../../../GlobalContext'
import useDimension from '../../../../../customHooks/useDimension'

import FormContact from './FormContact'
import LineUser from './LineUser'

export default function ModuleContacts({users, activities, handleFormOpenContact}){
  const context = useGlobalContext()[0];
  const dimension = useDimension()
  const [ selectedUser, setSelectedUser ] = useState()
  const [ isFormContactOpen, setIsFormContactOpen ] = useState(false)

  const companyReducer = context.companyReducer;
  const authReducer = context.authReducer;

  // USER TO DETECT IF FORMCONTACT IS OPEN
  // useEffect(()=>{
  //   if(selectedUser || isFormContactOpen){
  //     handleFormOpenContact(true)
  //   }else{
  //     handleFormOpenContact(false)
  //   }
  // },[isFormContactOpen,selectedUser])

  function selectUser(id){
    setSelectedUser(id)
    setIsFormContactOpen(false)
  }


  function renderListUsers(){
    let sortUser = orderBy(users,[user=> user.role === "Owner","profile.lastName"],["asc","desc"])

    if(authReducer.user.role === "Owner"){
      let indexAuthUser = sortUser.findIndex((user,i) => {
        return user._id == authReducer.user._id
      })
      if(indexAuthUser > 0 ){
        sortUser = [ sortUser[indexAuthUser], ...sortUser.filter((d,i)=> i !== indexAuthUser)]
      }
    }

    return (
      <>
        { sortUser.map((user, i)=>(
          <LineUser key={"user-"+i}
           user={user}
           isLoading={companyReducer.isUserLoading ? companyReducer.isUserLoading.map.get(user._id) : null}
           cancel={() => cancel()}
           selectUser={selectUser}
           activities={activities}
           edit={selectedUser === user._id}
          />
        ))}
      </>
    )
  }

  function cancel(){
    setSelectedUser(null)
    setIsFormContactOpen(false)
  }

  function toggleModal(){
    setSelectedUser(null)
    setIsFormContactOpen(!isFormContactOpen)
  }

  return (
    <>
    <p className="section-title">Inscrire les collaborateurs présents</p>
    {renderListUsers()}
    {!isFormContactOpen && (users.length > 0 || dimension.width < 768) && !selectedUser ?
      <div className="centered">
        <div className="add-contact" onClick={toggleModal}>
          <p >Ajouter un contact</p>
          <div className="add" >+ </div>
        </div>
      </div> : null
    }

    { isFormContactOpen || (users.length === 0 && dimension.width > 767) ?
      <FormContact
       key="add"
       isLoading={companyReducer.isUserLoading ? companyReducer.isUserLoading.map.get("add") : null}
       activities={activities}
       cancel={() => cancel()}
      /> : null
    }

    </>
  )
}
