import React, { useEffect, useState } from 'react'
import SupportContact from '../../../../partials/SupportContact'
import { useHistory } from "react-router-dom"
import { useGlobalContext } from '../../../../GlobalContext'
import { formatDataShowrooms } from '../helpers'
import { createShowroom, updateShowroom } from '../../../../../actions/showroom';
import stand from '../../../../../assets/images/picto-stand.svg';

import styles from './recapitulatif.module.scss';

export default function Recapitulatif(props){
  const history = useHistory()
  const [ context, dispatch ] = useGlobalContext();
  const { authReducer, showRoomReducer } = context;
  const [ validation , setValidation ] = useState({})
  const { exhibition } = authReducer;

  //ACTIONS
  const _createShowroom = (data) => createShowroom(dispatch,data)
  const _updateShowroom = (data,id) => updateShowroom(dispatch,data,id)

  // VALIDATE
  useEffect(() => {
    if(props.isLoading) return;

    const obj = {};
    if (
      exhibition.displayShowroomSize &&
      !showRoomReducer?.formData?.dimension
    ) {
      obj.dimension = "Vous n'avez pas indiqué les dimensions du stand"
    }

    setValidation(obj);

  }, [
    props.isLoading,
    exhibition.displayShowroomSize,
  ]);

  useEffect(()=>{
    if(showRoomReducer.submitted){
      history.push('/stand/submit-succeed')
    }
  },[showRoomReducer.submitted]);

  function submit(){
    // UPDATE CREATE SHOWROOM
    let showroomData = formatDataShowrooms(showRoomReducer.formData);

    if(!showRoomReducer.formData._id){
      _createShowroom(showroomData)
    }else{
      _updateShowroom(showroomData,showRoomReducer.formData._id)
    }
  }

  function renderSubmit(){
    return (
      <>
      { (Object.values(validation).length > 0) ?
        null :
        <div className={styles["container-submit"]}>
          <button onClick={submit}
            className={showRoomReducer.isLoading ? styles.loading : "" }
          >
            {showRoomReducer.formData._id ? 'Modifier mon stand' : 'Valider mon stand'}
            {showRoomReducer.isLoading ?
              <div className="loader"></div> : null
            }
          </button>
        </div>
      }
      </>
    )
  }

  return (
    <> 
      <div className={`step ${styles.recapitulatif}`}>
        <p className="section-title">Récapitulatif</p>
        <section>
          <div className={styles.dimension}>
            <div className={styles["info-dimension"]}>
              {!exhibition.displayShowroomSize && !validation.dimension && (
                <img className={styles.stand} src={stand} alt="stand" />
              )}
              { showRoomReducer.formData?.dimension ? (
                  <>
                  <p>Dimension validée<br/> (en m) <span className={styles["hide-on-mobile"]}>:</span></p>
                  <p className={styles.dimension}>{showRoomReducer.formData?.dimension}</p>
                </>
              ) :
              <p className={`warn ${styles.warn}`}>{validation.dimension}</p>}
            </div>
          </div>
          <div className={styles["recap-options"]}>
            {(showRoomReducer.formData?.option?.countChair 
              || (showRoomReducer.formData?.option?.chair && typeof showRoomReducer.formData?.option?.chair === 'number')) ? 
            (
              <p>
                {showRoomReducer.formData.option.countChair || showRoomReducer.formData?.option?.chair}
                {(showRoomReducer.formData.option.countChair || showRoomReducer.formData?.option?.chair) > 1 ?
                  " chaises." : " chaise."
                }
               </p>
            ) : null}
            {(showRoomReducer.formData?.option?.countTable 
              || (showRoomReducer.formData?.option?.table && typeof showRoomReducer.formData?.option?.table === 'number')) ? 
            (
              <p>
                {showRoomReducer.formData.option.countTable || showRoomReducer.formData?.option?.table}
                {(showRoomReducer.formData.option.countTable || showRoomReducer.formData?.option?.table) > 1 ?
                  " tables." : " table."
                }
               </p>
            ) : null}
            {(showRoomReducer.formData?.option?.fridgeLength) ? 
            (
              <p>
                Longueur de vitrines réfrigérées en mètres :
                {` ${showRoomReducer.formData.option.fridgeLength}`}
               </p>
            ) : null}
            {(showRoomReducer.formData?.option?.countFridge 
              || (showRoomReducer.formData?.option?.fridge && typeof showRoomReducer.formData?.option?.fridge === 'number')) ? 
            (
              <p>
                {showRoomReducer.formData.option.countFridge || showRoomReducer.formData?.option?.fridge}
                {(showRoomReducer.formData.option.countFridge || showRoomReducer.formData?.option?.fridge) > 1 ?
                  " vitrines réfrigérées." : " vitrine réfrigérée."
                }
               </p>
            ) : null}
            {(showRoomReducer.formData?.option?.power && showRoomReducer.formData?.option?.powerQuantity) && (
              <p>
                {showRoomReducer.formData?.option?.powerQuantity > 1 
                  ? `Vous avez besoin de ${showRoomReducer.formData.option.powerQuantity}KW.`
                  : 'Vous avez besoin de 1KW.'
                }
              </p>
            )}
            {renderSubmit()}
          </div>
        </section>
      </div>
      <SupportContact className={styles['support-contact']} />
    </>
  )

}
