import _ from 'lodash'
import format from 'format-number';

export function getProductsInShoppingList(products,shoppingList,uniq){
  let productsInShoppingList = []

  products.forEach((product)=>{
    let find = _.get(shoppingList,[product.company._id,product._id])
    if(find && find.count > 0){
      if(uniq){
        productsInShoppingList.push(product)
      }else{
        let arr = new Array(find.count).fill(product,0,find.count)
        productsInShoppingList.push(...arr)
      }
    }
  })

  return productsInShoppingList
}

export function fnFormat (number) {
  return format({integerSeparator: " "})(number.toFixed(2))
}

export function fnFormatFixed (number) {
  // USE ON DISCOUNT %
  // let value = format({integerSeparator: " "})(number.toFixed(1));
  let value = format({integerSeparator: " "})(number.toFixed(0));
  return value;
  // if(/.0/.test(value)){
  //   return value.replace(".0","")
  // }else{
  //   return value
  // }
}
