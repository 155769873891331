import {
  QUIZZ_USER,
  AUTH_LOGIN,
  AUTH_GET_EXHIBITION,
  AUTH_LIST_CONTACTS,
  AUTH_LIST_EXHIBITIONS,
  AUTH_LIST_COMPANIES,
  AUTH_LOGOUT,
  AUTH_GET_USER,
  RESET_ERROR,
  RESET_AUTH,
  ERROR_AUTH
} from "../actions/types"

const initialState = {
  isAuthenticated : false,
  contacts : null,
  companies : null,
  exhibitions : null,
  exhibition: null,
  user : null,
  error : null
};

let updatedUser = null

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN :
      localStorage.setItem("id",action.payload.user._id);
      localStorage.setItem("token",action.payload.token);
      if (action.payload.user?.company?._id) localStorage.setItem("company",action.payload.user.company._id);
      return {...state,
        isAuthenticated : true,
        user : {
          _id : action.payload.user._id,
          company : action.payload.user?.company?._id
        }
      };
    case AUTH_GET_EXHIBITION:
      return {
        ...state,
        exhibition: action.payload,
      };
    case QUIZZ_USER :
      return {...state,
        user : {
          ...state.user,
          quizz : action.payload.user.quizz
        }
      }
    case AUTH_LIST_CONTACTS :
      return {...state, contacts : action.payload.contacts}
    case AUTH_LIST_EXHIBITIONS :
      return {...state, exhibitions : action.payload.exhibitions}
    case AUTH_LIST_COMPANIES :
      return {...state, companies : action.payload.companies}
    case AUTH_LOGOUT:
      localStorage.removeItem("id")
      localStorage.removeItem("company")
      localStorage.removeItem('token');
      localStorage.removeItem('exhibition');
      window.location.assign("#/")
      return {...state, error : null, isAuthenticated : false, user : null, contacts : null, companies : null};
    case AUTH_GET_USER :
      updatedUser = action.payload
      //Keep actual value in state user
      //We got it from the login logic after dropdown selection
      updatedUser.company = state.user && state.user.company ? state.user.company : localStorage.getItem("company")
      return {...state, user : updatedUser}
    case RESET_ERROR :
      return {...state, error : null};
    case RESET_AUTH :
      return { isAuthenticated : false, user : null, error : null}
    case ERROR_AUTH :
      return {...state, error : action.payload};
    default:
      return {...state};
  }
}
