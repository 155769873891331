import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { NavLink, useParams } from 'react-router-dom'
import { useGlobalContext } from '../../../GlobalContext'
import { getOrderById, updateOrder } from '../../../../actions/orders'
import useDimension from '../../../../customHooks/useDimension'
import usePrevious from '../../../../customHooks/usePrevious'

import Loader from '../../../partials/Loader'
import IconQRCode from '../../../../assets/images/icons/icon-qrcode.svg'

import ListProducts from './ListProducts'

// import ListProducts from './ListProducts'
// import LineProduct from './LineProduct'
import ValidationOrder from './ValidationOrder'


export default function Order({ readOnly = false}){
  const [ context, dispatch ] = useGlobalContext()
  const productsReducer = context.productsReducer
  const [ sort, setSort ] = useState({})
  const [allProducts, setAllProducts] = useState()
  const [filteredProducts, setFilteredProducts] = useState()
  const [ selectedOrder, setSelectedOrder] = useState()
  const [ confirmedOnMount, setConfirmedOnMount ] = useState(false)

  const params = useParams()
  const dimension = useDimension()

  //ACTIONS
  const _getOrderById = (id) => getOrderById(dispatch,id)
  const _updateOrder = (order,id) => updateOrder(dispatch,order,id)

  useEffect(()=>{
    if(productsReducer.orders){
      _getOrderById(params.id);
    }
  },[productsReducer.orders])

  useEffect(()=>{
    if(!productsReducer.order) return;

    let countedProducts = []

    let countBy = _.countBy(productsReducer.order.products,"_id")

    for(let id in countBy){
      let findProduct = productsReducer.order.products.find((d) => d._id === id)
      countedProducts.push({
        ...findProduct,
        orderId : productsReducer.order._id,
        count : countBy[id],
        total : countBy[id]*findProduct.price
      })
    }

    countedProducts = _.sortBy(countedProducts, (d) => new Date(d.updatedAt).getTime())

    setAllProducts(countedProducts)
    setFilteredProducts(countedProducts)

  },[productsReducer.order])

  //SORTING PRODUCTS
  useEffect(()=>{
    if(!allProducts) return;
    let products = _.cloneDeep(allProducts)

    if(sort && !_.isEmpty(sort)){
      products = _.orderBy(products,sort.key,
        [sort.order,
        (d) => new Date(d.updatedAt).getTime()
      ])
    }

    setFilteredProducts(products)
  },[allProducts,sort])

  function sortProducts(sort){
    setSort(sort)
  }

  //

  return(
    <>
      {selectedOrder && <ValidationOrder orderId={selectedOrder._id} triggerClose={() => setSelectedOrder()}/> }
      <div className="order">
        <div className="content large">
          <div className="title-page">
            <h2>
              Détail de la commande
            </h2>
            <NavLink to="/orders">
              <button>Retour</button>
            </NavLink>
          </div>
          <div className="container-order">
            <h2 className="only-on-tablet">
              Détail de la commande
            </h2>
            { filteredProducts ?
              <>
                <p className="provider">{filteredProducts[0].company.name}</p>
                <ListProducts readOnly={readOnly} sort={sort} sortProducts={(obj) => sortProducts(obj)} products={filteredProducts} />
                { !readOnly &&
                  <div className="container-btn-validate">
                    { dimension.width > 1024 ?
                      <button onClick={() => setSelectedOrder(productsReducer.order)}>Valider cette commande</button> :
                      <NavLink to={`/validate-order/${params.id}`}><button>Valider cette commande</button></NavLink>
                    }
                    <div>
                      <img src={IconQRCode} alt="qr code"/>
                      <p>
                        La validation de la commande se fait en <span className="medium">scannant le QRcode du fournisseur</span>
                      </p>
                    </div>
                  </div>
                }
               
              </> :
              <div className="loader-order"><Loader/></div>
            }
          </div>
        </div>
      </div>
    </>
  )
}
